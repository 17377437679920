import "./Register.scss";
import React, { FormEvent, useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { validateEmail } from "../../../lib/utils.js";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet-async";

const Register = () => {
  // const navigate = useNavigate();

  /** ---> All states below */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!password || !cPassword || !email) {
      toast.error("All fields are required!");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Please enter a valid email!");
      return;
    } else if (password.length < 6) {
      toast.error("Password must be minimum 6 characters long!");
      return;
    } else if (password !== cPassword) {
      toast.error("Password and confirm password must match!");
      return;
    }

    setLoading(true);
    toast.loading("Creating account...");

    try {
      await axiosClient.post("auth/account-creation", {
        email,
        password,
      });

      toast.dismiss();
      toast.success(
        "Account created successfully, Please verify  your email.",
        {
          duration: Infinity,
        }
      );

      /** ---> Redirecting user according to login */
      setEmail("");
      setPassword("");
      setCPassword("");
    } catch (error) {
      console.log(error);
      toast.dismiss();
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message);
      } else {
        toast.error("Failed to create an Account!");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="Register hero gradient-background white-font">
      <Helmet>
        <title>Beligent - Create Account</title>
      </Helmet>
      <div className="text-box">
        <div>
          <h1>Digital Product Passport</h1>
          <h1 className="subtitle">Your journey of innovation begins here.</h1>
          <hr />
          <p className="main-description">Register and start creating now!</p>
        </div>
      </div>

      {/* ---> instead of multicontainer */}
      <div className="container">
        <h2 className="heading">Create Account</h2>
        <form onSubmit={handleLogin} className="form">
          <div className="input-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password *</label>
            <input
              type="password"
              value={password}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="cPassword">Confirm Password *</label>
            <input
              type="password"
              id="cPassword"
              value={cPassword}
              onChange={(e) => setCPassword(e.target.value)}
            />
          </div>

          <br />
          <button className="btn action-button" disabled={loading}>
            Create Account
          </button>
        </form>
        <p className="no-account">
          Already have an account? <NavLink to="/auth/login">Login </NavLink>
        </p>
      </div>
    </div>
  );
};

export default Register;
