/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useEffect, useState } from "react";

import "./SubscriptionSelection.scss";
import { Link, useNavigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { useSetAtom } from "jotai/react";
import { authAtom, updateUserAtom } from "../../../atoms/authAtom.ts";
import { Helmet } from "react-helmet-async";
import { ISubscriptionPlan } from "../../../interfaces/ISubscriptionPlan.ts";
import { axiosClient } from "../../../lib/axiosClient.ts";

// const plan = {
//   products: [
//     {
//       category: "Vivaldi Passport",
//       "default-blockchain": "polygon",
//       "default-currency": "EUR",
//       pricing: [
//         {
//           name: "Discovery",
//           features: {
//             passports: 1,
//             documents: 1,
//             events: 0,
//             updates: 0,
//             modality: "monthly",
//           },
//           blockchain: {
//             ethereum: {
//               CHF: 100,
//               EUR: 110,
//               USD: 120,
//             },
//             polygon: {
//               CHF: 51,
//               EUR: 52,
//               USD: 53,
//             },
//           },
//         },
//         {
//           name: "Workshop",
//           features: {
//             passports: 5,
//             documents: 5,
//             events: 5,
//             updates: 5,
//             modality: "yearly",
//           },
//           blockchain: {
//             ethereum: {
//               CHF: 300,
//               EUR: 300,
//               USD: 300,
//             },
//             polygon: {
//               CHF: 150,
//               EUR: 150,
//               USD: 150,
//             },
//           },
//         },
//         {
//           name: "Studio",
//           features: {
//             passports: 6,
//             documents: 20,
//             events: 40,
//             updates: 80,
//             modality: "yearly",
//           },
//           blockchain: {
//             ethereum: {
//               CHF: 600,
//               EUR: 600,
//               USD: 600,
//             },
//             polygon: {
//               CHF: 150,
//               EUR: 150,
//               USD: 150,
//             },
//           },
//         },
//         {
//           name: "Factory",
//           features: {
//             passports: 10,
//             documents: 40,
//             events: 80,
//             updates: 160,
//             modality: "yearly",
//           },
//           blockchain: {
//             ethereum: {
//               CHF: 600,
//               EUR: 600,
//               USD: 600,
//             },
//             polygon: {
//               CHF: 150,
//               EUR: 150,
//               USD: 150,
//             },
//           },
//         },
//       ],
//     },
//   ],
// };

const SubscriptionSelection = () => {
  const navigate = useNavigate();
  const { user } = useAtomValue(authAtom);
  const setUpdatedUser = useSetAtom(updateUserAtom);
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    ISubscriptionPlan[]
  >([]);

  /** ---> Fetching plans data on page load. */
  useEffect(() => {
    handleFetchSubscriptionPlans();
  }, []);

  const handleFetchSubscriptionPlans = async () => {
    try {
      const res = await axiosClient.get("/subscription-plans");
      setSubscriptionPlans(res.data.plans);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBuyPlan = (planDetails, selectedCurrency, selectedBlockchain) => {
    navigate("/onboarding/subscription-payment", {
      state: {
        plan: planDetails,
        currency: selectedCurrency,
        blockchain: selectedBlockchain,
      },
    });
  };

  return (
    <div className="SubscriptionSelection hero gradient-background ">
      <div className="page-header">
        <h2>Step 4 / 5: Subscription </h2>
        <p>Please select your favourite currency and the blockchain</p>

        <Link to={"/onboarding/kyc-verification"} className="go-back">
          Go back to step 3
        </Link>
      </div>

      <div className="container">
        {subscriptionPlans.length !== 0 && (
          <SubscriptionPlanCard
            plans={subscriptionPlans}
            handleBuyPlan={handleBuyPlan}
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionSelection;

const SubscriptionPlanCard = ({ plans, handleBuyPlan }) => {
  const [selectedBlockchain, setSelectedBlockchain] = useState("polygon");
  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  return (
    <div className="SubscriptionSelection subscription-container">
      <Helmet>
        <title>Beligent - Subscription Selection</title>
      </Helmet>
      <div className="subscription-header">
        <div>
          <span>Blockchain: </span>
          <select
            value={selectedBlockchain}
            onChange={(e) => setSelectedBlockchain(e.target.value)}
          >
            {Object.keys(plans[0].pricing).map((blockchain) => (
              <option key={blockchain} value={blockchain}>
                {blockchain}
              </option>
            ))}
          </select>
        </div>
        <div>
          <span>Currency: </span>
          <select
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
          >
            {Object.keys(plans[0].pricing[selectedBlockchain]).map(
              (currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              )
            )}
          </select>
        </div>
      </div>
      <div className="card-container">
        {plans.map((plan) => (
          <div className="subscription-card" key={plan.id}>
            <h3>{plan.category}</h3>
            <p className="plan-name">{plan.name}</p>
            <p className="plan-period">Includes ({plan.modality}):</p>
            {Object.entries(plan).map(
              ([key, value]) =>
                ["passports", "documents", "events", "updates"].includes(
                  key
                ) && (
                  <p key={key} className="plan-feature">
                    {String(value)} {key}
                  </p>
                )
            )}
            <p className="plan-price">
              {plan.pricing[selectedBlockchain]?.[selectedCurrency] || "N/A"}{" "}
              {selectedCurrency}
            </p>
            <button
              onClick={() =>
                handleBuyPlan(plan, selectedCurrency, selectedBlockchain)
              }
              className="buy-button"
            >
              Buy now
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
