/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import { atom } from "jotai";
import { atomWithStorage } from 'jotai/utils';

export const passportPreviewDataAtom = atomWithStorage('passportPreviewData',{
  smartContractAddress: "5",
  tokenId: "",
  imageURL: "",
  passportName: "",
  passportTimeStamp: "",
  passportDescription: ""
});
