/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import "./SubscriptionPayment.scss";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ISubscriptionPlan } from "../../../interfaces/ISubscriptionPlan";
import { axiosClient } from "../../../lib/axiosClient.ts";
import toast from "react-hot-toast";
import { useSetAtom } from "jotai";
import { updateUserAtom } from "../../../atoms/authAtom.ts";

const SubscriptionPayment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const { plan, currency, blockchain } = state as {
    plan: ISubscriptionPlan;
    currency: string;
    blockchain: string;
  };

  const setUpdatedUser = useSetAtom(updateUserAtom);

  useEffect(() => {
    if (!state) {
      navigate("/onboarding/subscription-selection");
    }
  }, [state, navigate]);
console.log(plan, currency, blockchain);
  const handleMakePayment = async () => {
    try {
      await axiosClient.post("/payment/success", {
        plan,
        currency,
        blockchain,
      });
      toast.success("Payment done successfully.");
      setUpdatedUser({
        onboardingStep: "kyc-pending",
      });
      navigate("/onboarding/kyc-verification-pending", { replace: true });
    } catch (error) {
      console.log(error);
      toast.error("Failed to complete Payment!");
    }
  };

  if (!state) {
    return <p>Loading...</p>;
  }

  return (
    <div className="SubscriptionPayment hero gradient-background white-font">
      <Helmet>
        <title>Beligent - Subscription Payment</title>
      </Helmet>

      <div className="page-header">
        <h2>Step 5 / 5: Payment </h2>

        <Link to={"/onboarding/subscription-selection"} className="go-back">
          Go back to step 4
        </Link>
        <p className="amount">
          Amount to pay: {plan.pricing[blockchain][currency]} {currency}
        </p>
      </div>

      <div className="payment-container">
        <form className="payment-form">
          <input type="text" placeholder="Card Number*" required />
          <input type="text" placeholder="Cardholder*" required />
          <div className="card-details">
            <input type="text" placeholder="MM/YY" required />
            <input type="text" placeholder="CVV*" required />
          </div>
          <div className="save-card">
            <input type="checkbox" id="save-card" />
            <label htmlFor="save-card">Save card details for future use</label>
          </div>

          <button onClick={handleMakePayment} className="pay-button">
            Pay {plan.pricing[blockchain][currency]} {currency}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SubscriptionPayment;
