import "./ResetPassword.scss";
import React, { FormEvent, useState } from "react";
import toast from "react-hot-toast";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet-async";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  /** ---> All states below */
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password.length < 6) {
      toast.error("Password must be minimum 6 characters long!");
      return;
    } else if (password !== cPassword) {
      toast.error("Password and Confirm Password not matched!");
      return;
    }

    setLoading(true);
    toast.loading("Resetting Password.");

    try {
      await axiosClient.post("auth/reset-password", {
        password,
        token,
      });
      toast.dismiss();
      toast.success("Password Reset successfully.");
      setPassword("");
      setCPassword("");
      navigate("/auth/login");
    } catch (error) {
      toast.dismiss();
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message);
      } else {
        toast.error("Failed to resert password!");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (!token) return <Navigate to={"/auth/login"} />;

  return (
    <div className="ResetPassword hero gradient-background white-font">
      <Helmet>
        <title>Beligent - Reset Password</title>
      </Helmet>
      <div className="text-box">
        <div>
          <h1>Digital Product Passport</h1>
          <h1 className="subtitle">Your journey of innovation begins here.</h1>
          <hr />
          <p className="main-description">Reset your password here !</p>
        </div>
      </div>

      {/* ---> instead of multicontainer */}
      <div className="container">
        <h2 className="heading">Reset Password</h2>
        <form onSubmit={handleResetPassword} className="form">
          <div className="input-group">
            <span>
              New Password *
            </span>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <span>
              Confirm New Password *
            </span>
            <input
              type="password"
              value={cPassword}
              onChange={(e) => setCPassword(e.target.value)}
              required
            />
          </div>

          <br />
          <button className="btn action-button" disabled={loading}>
            Reset
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
