/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { Link } from "react-router-dom";
import { useAtomValue } from "jotai";
import { authAtom } from "../../../atoms/authAtom.ts";
import { Helmet } from "react-helmet-async";

const Dashboard = ({  }) => {
  const { user } = useAtomValue(authAtom);
  const [currentUser, setCurrentUser] = useState(() => {
    return user || (localStorage.getItem("_BLGT_user") ? JSON.parse(localStorage.getItem("_BLGT_user") as string) : null) || null;
  });

  useEffect(() => {
    if (!user) {
      const storedUser = localStorage.getItem("_BLGT_user");
      if (storedUser) {
        setCurrentUser(JSON.parse(storedUser));
      }
    }
  }, [user]);

  /*** RENDERING ***********************************************************/
  const renderWelcomeMessage = () => (
    <div className="welcome-message">
      <h2>Welcome to Your Passport Dashboard!</h2>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );

  return (
    <div className="Dashboard">
      <Helmet>
        <title>Beligent - Dashboard</title>
      </Helmet>
      {renderWelcomeMessage()}
      <div className="d-flex justify-content-center py-5 align-items-center vh-50 outer-container">
        <div className="col-md-5 d-flex justify-content-center">
          <Link to="/platform/passports/collection" className="passport-card">
            <img
              className="passport-icon faded"
              src="/collection.svg"
              alt="Collection"
            />
            <p className="passport-text">Manage and oversee your passports</p>
          </Link>
        </div>

        <div className="col-md-5 d-flex justify-content-center">
          <Link to="/platform/passports/create" className="passport-card">
            <img className="passport-icon" src="/passport.svg" alt="Passport" />
            <p className="passport-text center">Generate new<br />Digital Product Passports</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
