/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { ChangeEvent, FormEvent, useState } from "react";
import "./PassportCreation.scss";
import { axiosClient } from "../../../lib/axiosClient.ts";
import Accordion from "../../../components/Accordion/Accordion.tsx";
import DropZone from "../../../components/DropZone/DropZone.tsx";
import { X } from "lucide-react";
import toast from "react-hot-toast";
import { useAtomValue } from "jotai";
import { authAtom } from "../../../atoms/authAtom.ts";
import { Helmet } from "react-helmet-async";

const options = [
  { value: "violin", label: "Violin" },
  { value: "viola", label: "Viola" },
  { value: "cello", label: "Cello" },
  { value: "doubleBass", label: "Double Bass" },
];

const initialGeneralInfo = {
  name: "",
  instrumentType: "",
  manufacturer: "",
  model: "",
  serialNumber: 1,
  yearOfCreation: "",
  placeOfCreation: "",
  description: "",
};

const initialMaterialsInfo = {
  topWood: "",
  topWoodGrainPattern: "",
  backWood: "",
  backWoodFlameFigure: "",
  woodNaturalSeasoning: "",
  varnish: "",
  dyes: "",
  coloredEssences: "",
};

const initailMeasurements = {
  bodyLength: "",
  upperBoutWidth: "",
  centerBoutWidth: "",
  lowerBoutWidth: "",
  upperRibHeight: "",
  centerRibHeight: "",
  lowerRibHeight: "",
};

const initialCompositionAndOrigin = {
  top: "",
  back: "",
  purfling: "",
  internalBlocks: "",
  fingerboard: "",
  tailPiece: "",
  pegs: "",
  scroll: "",
  chinrest: "",
  varnish: "",
  dyes: "",
  coloredEssences: "",
  glues: "",
};
const PassportCreation = () => {
  const { user } = useAtomValue(authAtom);
  /** ---> All states. */
  const [passportIssuerAddress, setPassportIssuerAddress] = useState("");
  const [passportIssuerName, setPassportIssuerName] = useState("");
  const [passportTimestamp, setVerifiedNftCertificateTimestamp] = useState("");
  const [passportDone, setPassportDone] = useState(false);
  const [passportInProgress, setPassportInProgress] = useState(false);
  const [txnHash, setTxnHash] = useState("");
  const [smartContractAddress, setSmartContractAddress] = useState("");
  /** ---> Form States */
  const [files, setFiles] = useState<File[]>([]);
  const [generalInfo, setGeneralInfo] = useState(initialGeneralInfo);
  const [materialsInfo, setMaterialsInfo] = useState(initialMaterialsInfo);
  const [measurements, setMeasurements] = useState(initailMeasurements);
  const [maintenance, setMaintenance] = useState("");
  const [compositionAndOrigin, setCompositionAndOrigin] = useState(
    initialCompositionAndOrigin
  );

  const handleFilesSelected = (selectedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };
  const handleRemoveFile = (indexToRemove: number) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleGeneralInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGeneralInfo((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleMaterialInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMaterialsInfo((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleMeasurementsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMeasurements((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleCompositionAndOriginChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setCompositionAndOrigin((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const makePassport = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!files[0]) {
      toast.error("Please select an image first!");
      return;
    }

    setPassportInProgress(true);
    toast.loading("Creating passport");

    const formData = new FormData();
    formData.append("image", files[0]);
    formData.append("generalInfo", JSON.stringify(generalInfo));
    formData.append("materialsInfo", JSON.stringify(materialsInfo));
    formData.append("measurements", JSON.stringify(measurements));
    formData.append("maintenance", JSON.stringify(maintenance));
    formData.append(
      "compositionAndOrigin",
      JSON.stringify(compositionAndOrigin)
    );

    try {
      const res = await axiosClient.post("passports/v0", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setTxnHash(res.data.transactionReceipt.hash);
      setPassportIssuerAddress(res.data.transactionReceipt.from);
      setSmartContractAddress(res.data.transactionResponse.to);
      setPassportIssuerName(res.data.certificateData.creatorName);

      setPassportInProgress(false);
      setPassportDone(true);

      toast.dismiss();
      toast.success("Passport created successfully.");
    } catch (error) {
      toast.dismiss();
      toast.error("Passport creation failed!");
      console.error("Error making passport", error);
      setPassportInProgress(false);
    }
  };

  return (
    <main className="PassportCreation">
      <Helmet>
        <title>Beligent - Passport Creation</title>
      </Helmet>
      <WelcomeMessage user={user} />
      {!passportInProgress && !passportDone && (
        <>
          <p>First select the image representing your passport:</p>
          {files.length === 0 && (
            <DropZone
              onFilesSelected={handleFilesSelected}
              acceptedTypes={{ "image/*": [] }}
              multiple={true}
            />
          )}

          {/* Display Selected Files with Remove Option */}
          {files.length > 0 && (
            <div>
              <ul>
                {files.map((file, index) => (
                  <div className="image-preview">
                    <img src={URL.createObjectURL(file)} alt="Preview" />
                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="close-button"
                    >
                      <X size={18} />
                    </button>
                  </div>
                ))}
              </ul>
            </div>
          )}

          <PassportForm
            makePassport={makePassport}
            handleGeneralInfoChange={handleGeneralInfoChange}
            handleMaterialInfoChange={handleMaterialInfoChange}
            handleMeasurementsChange={handleMeasurementsChange}
            handleCompositionAndOriginChange={handleCompositionAndOriginChange}
            setMaintenance={setMaintenance}
          />
        </>
      )}
      {passportInProgress && !passportDone && <RenderInProgress />}
      {passportDone && (
        <RenderResultSuccess
          passportIssuerAddress={passportIssuerAddress}
          passportIssuerName={passportIssuerName}
          txnHash={txnHash}
          smartContractAddress={smartContractAddress}
        />
      )}
    </main>
  );
};

export default PassportCreation;

const WelcomeMessage = ({ user }) => (
  <div className="welcome-message">
    <h2>Passport Creation</h2>
    <p>You can create your passports.</p>
    <p>
      You write on blockchain, make sure to write all the in formation and chose
      an im age that represents your passport. The image must be in PNG or JPEG
      format and its size should not exceed 1MB.
    </p>
  </div>
);

const PassportForm = ({
  makePassport,
  handleGeneralInfoChange,
  handleMaterialInfoChange,
  handleMeasurementsChange,
  handleCompositionAndOriginChange,
  setMaintenance,
}) => {
  return (
    <form className="passport-form">
      {/* ---> Form Section 1 */}
      <Accordion title="General Information" isDefaultOpen={true}>
        <div className="form-section">
          <div className="inp-wrapper">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              required
              onChange={handleGeneralInfoChange}
            />
          </div>

          <div className="inp-wrapper">
            <label htmlFor="instrumentType">Instrument Type</label>
            <select
              name="instrumentType"
              id="instrumentType"
              onChange={handleGeneralInfoChange}
            >
              {options.map((opt) => {
                return (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="inp-wrapper">
            <label htmlFor="manufacturer">Manufacturer</label>
            <input
              type="text"
              name="manufacturer"
              id="manufacturer"
              required
              onChange={handleGeneralInfoChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="model">Model</label>
            <input
              type="text"
              name="model"
              id="model"
              required
              onChange={handleGeneralInfoChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="serialNumber">Serial Number</label>
            <input
              type="number"
              name="serialNumber"
              id="serialNumber"
              required
              onChange={handleGeneralInfoChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="yearOfCreation">Year Of Creation</label>
            <input
              type="number"
              name="yearOfCreation"
              id="yearOfCreation"
              required
              onChange={handleGeneralInfoChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="placeOfCreation">Place Of Creation</label>
            <input
              type="text"
              name="placeOfCreation"
              id="placeOfCreation"
              required
              onChange={handleGeneralInfoChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="description">Description</label>
            <input
              type="text"
              name="description"
              id="description"
              required
              onChange={handleGeneralInfoChange}
            />
          </div>
        </div>
      </Accordion>

      {/* ---> Form Section 2 (materials) */}
      <Accordion title="Materials">
        <div className="form-section">
          <div className="inp-wrapper">
            <label htmlFor="topWood">Top Wood</label>
            <input
              type="text"
              name="topWood"
              id="topWood"
              onChange={handleMaterialInfoChange}
            />
          </div>

          <div className="inp-wrapper">
            <label htmlFor="topWoodGrainPattern">Top Wood Grain Pattern</label>
            <input
              type="text"
              name="topWoodGrainPattern"
              id="topWoodGrainPattern"
              onChange={handleMaterialInfoChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="backWood">Back Wood</label>
            <input
              type="text"
              name="backWood"
              id="backWood"
              onChange={handleMaterialInfoChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="backWoodFlameFigure">BackWood Flame Figure</label>
            <input
              type="text"
              name="backWoodFlameFigure"
              id="backWoodFlameFigure"
              onChange={handleMaterialInfoChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="woodNaturalSeasoning">Wood Natural Seasoning</label>
            <input
              type="text"
              name="woodNaturalSeasoning"
              id="woodNaturalSeasoning"
              onChange={handleMaterialInfoChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="varnish">Varnish</label>
            <input
              type="text"
              name="varnish"
              id="varnish"
              onChange={handleMaterialInfoChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="dyes">Dyes</label>
            <input
              type="text"
              name="dyes"
              id="dyes"
              onChange={handleMaterialInfoChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="coloredEssences">Colored Essences</label>
            <input
              type="text"
              name="coloredEssences"
              id="coloredEssences"
              onChange={handleMaterialInfoChange}
            />
          </div>
        </div>
      </Accordion>

      {/* ---> Form Section 3 (measurements) */}

      <Accordion title="Measurements">
        <span>* Measurements in cm.</span>
        <div className="form-section">
          <div className="inp-wrapper">
            <label htmlFor="bodyLength">Body Length</label>
            <input
              type="number"
              name="bodyLength"
              id="bodyLength"
              onChange={handleMeasurementsChange}
            />
          </div>

          <div className="inp-wrapper">
            <label htmlFor="upperBoutWidth">Upper Bout Width</label>
            <input
              type="number"
              name="upperBoutWidth"
              id="upperBoutWidth"
              onChange={handleMeasurementsChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="centerBoutWidth">Center Bout Width</label>
            <input
              type="number"
              name="centerBoutWidth"
              id="centerBoutWidth"
              onChange={handleMeasurementsChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="lowerBoutWidth">Lower Bout Width</label>
            <input
              type="number"
              name="lowerBoutWidth"
              id="lowerBoutWidth"
              onChange={handleMeasurementsChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="upperRibHeight">Upper Rib Height</label>
            <input
              type="number"
              name="upperRibHeight"
              id="upperRibHeight"
              onChange={handleMeasurementsChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="centerRibHeight">Center Rib Height</label>
            <input
              type="number"
              name="centerRibHeight"
              id="centerRibHeight"
              onChange={handleMeasurementsChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="lowerRibHeight">Lower Rib Height</label>
            <input
              type="number"
              name="lowerRibHeight"
              id="lowerRibHeight"
              onChange={handleMeasurementsChange}
            />
          </div>
        </div>
      </Accordion>

      {/* ---> Form Section 4 (compositionAndOrigin) */}
      <Accordion title="Composition And Origin">
        <div className="form-section">
          <div className="inp-wrapper">
            <label htmlFor="top">Top</label>
            <input
              type="text"
              name="top"
              id="top"
              onChange={handleCompositionAndOriginChange}
            />
          </div>

          <div className="inp-wrapper">
            <label htmlFor="back">Back</label>
            <input
              type="text"
              name="back"
              id="back"
              onChange={handleCompositionAndOriginChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="scroll">Scroll</label>
            <input
              type="text"
              name="scroll"
              id="scroll"
              onChange={handleCompositionAndOriginChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="fingerboard">Finger board</label>
            <input
              type="text"
              name="fingerboard"
              id="fingerboard"
              onChange={handleCompositionAndOriginChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="tailPiece">Tail Piece</label>
            <input
              type="text"
              name="tailPiece"
              id="tailPiece"
              onChange={handleCompositionAndOriginChange}
            />
          </div>

          <div className="inp-wrapper">
            <label htmlFor="pegs">Pegs</label>
            <input
              type="text"
              name="pegs"
              id="pegs"
              onChange={handleCompositionAndOriginChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="purfling">Purfling</label>
            <input
              type="text"
              name="purfling"
              id="purfling"
              onChange={handleCompositionAndOriginChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="internalBlocks">Internal Blocks</label>
            <input
              type="text"
              name="internalBlocks"
              id="internalBlocks"
              onChange={handleCompositionAndOriginChange}
            />
          </div>

          <div className="inp-wrapper">
            <label htmlFor="chinrest">Chinrest</label>
            <input
              type="text"
              name="chinrest"
              id="chinrest"
              onChange={handleCompositionAndOriginChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="varnish">Varnish</label>
            <input
              type="text"
              name="varnish"
              id="varnish"
              onChange={handleCompositionAndOriginChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="dyes">Dyes</label>
            <input
              type="text"
              name="dyes"
              id="dyes"
              onChange={handleCompositionAndOriginChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="coloredEssences">Colored Essences</label>
            <input
              type="text"
              name="coloredEssences"
              id="coloredEssences"
              onChange={handleCompositionAndOriginChange}
            />
          </div>
          <div className="inp-wrapper">
            <label htmlFor="glues">Glues</label>
            <input
              type="text"
              name="glues"
              id="glues"
              onChange={handleCompositionAndOriginChange}
            />
          </div>
        </div>
      </Accordion>

      {/* ---> Form Section 5 (maintenance) */}
      <Accordion title="Maintenance">
        <div className="form-section">
          <div className="inp-wrapper">
            <label htmlFor="maintenance">Maintenance</label>
            <input
              type="text"
              name="maintenance"
              id="maintenance"
              onChange={(e) => setMaintenance(e.target.value)}
            />
          </div>
        </div>
      </Accordion>

      <div>
        <button
          onClick={makePassport}
          type="button"
          className="btn action-button mint-button + ('disable-button')"
        >
          Create a Passport
        </button>
      </div>
      <br></br>
    </form>
  );
};

const RenderInProgress = () => (
  <>
    <div>
      <img src="/progress.gif" width="200px" />
    </div>
    <div>The Passport is being recorded on the blockchain...</div>
  </>
);

const RenderResultSuccess = (props) => {
  const {
    passportIssuerAddress,
    passportIssuerName,
    txnHash,
    smartContractAddress,
  } = props;
  return (
    <div className="section-content">
      <h5>Your Digital Product Passport is ready!</h5>
      <div className="certificate-preview">
        <span>Issuer address: </span>
        <span>{passportIssuerAddress}</span>
        <br></br>
        <span>Issuer name: </span>
        <span>{passportIssuerName}</span>
        <br></br>
        {/* <span>Certificate timestamp: </span>
                <span>{passportTimestamp}</span> */}
        <br></br>
      </div>
      {/* PassportViewer it on the <a href="./../passport/">Validator</a> (
            <a
                href={`./../passport/?address=${encodeURIComponent(
                    smartContractAddress
                )}&tokenId=${encodeURIComponent(smartContractAddress)}`}
                target="_blank"
            >
                new page
            </a>
            ). */}

      <div>
        <p>
          Transaction on &nbsp;
          <a
            href={`https://amoy.polygonscan.com/tx/${txnHash}`}
            target="_blank"
            rel="noreferrer"
          >
            PolygonScan
          </a>
          .
        </p>
        <p>
          See it on &nbsp;
          <a
            href={`https://testnets.opensea.io/assets/amoy/${smartContractAddress}/0`}
            target="_blank"
            rel="noreferrer"
          >
            OpenSea
          </a>{" "}
          (not real one, just for display) .
        </p>
      </div>
    </div>
  );
};
