/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";

import "./PrivacyPolicy.scss";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => (
  <div className="PrivacyPolicy">
    <Helmet>
      <title>Beligent - Privacy Policy</title>
    </Helmet>
    <div class="container">
      <h1>Privacy Policy</h1>

      <p>
        Welcome to Beligent. We value your privacy and are committed to
        protecting your personal data. This Privacy Policy explains how we
        collect, use, and safeguard your information when you use our SaaS
        platform and related services.
      </p>

      <h2>1. Data We Collect</h2>
      <p>We may collect the following types of data:</p>
      <ul>
        <li>
          <strong>Personal Identification Information:</strong> Name, email
          address, phone number, billing address, etc.
        </li>
        <li>
          <strong>Account Information:</strong> Username, password, and
          preferences.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you interact with
          our platform, including IP address, browser type, and activity logs.
        </li>
        <li>
          <strong>Payment Information:</strong> Billing details and transaction
          records (processed securely by third-party payment processors).
        </li>
      </ul>

      <h2>2. How We Use Your Data</h2>
      <p>We use your data for the following purposes:</p>
      <ul>
        <li>To provide and maintain our SaaS platform.</li>
        <li>To process transactions and manage subscriptions.</li>
        <li>
          To communicate with you, including updates, support, and promotional
          offers.
        </li>
        <li>To improve and personalize your user experience.</li>
        <li>
          To comply with legal obligations and enforce our terms of service.
        </li>
      </ul>

      <h2>3. Sharing Your Data</h2>
      <p>We only share your data under the following circumstances:</p>
      <ul>
        <li>
          With trusted third-party service providers (e.g., hosting, analytics,
          payment processors).
        </li>
        <li>To comply with legal obligations or protect our rights.</li>
        <li>
          In the event of a merger, acquisition, or asset sale, where your data
          may be transferred to the new entity.
        </li>
      </ul>

      <h2>4. Data Retention</h2>
      <p>
        We retain your personal data only for as long as necessary to fulfill
        the purposes outlined in this policy or as required by law.
      </p>

      <h2>5. Your Rights</h2>
      <p>You have the following rights regarding your data:</p>
      <ul>
        <li>Access your data and request a copy of it.</li>
        <li>Request corrections to your data.</li>
        <li>Request the deletion of your data, where applicable.</li>
        <li>
          Withdraw consent for data processing (if consent was the legal basis).
        </li>
        <li>File a complaint with a data protection authority.</li>
      </ul>

      <h2>6. Security</h2>
      <p>
        We implement appropriate technical and organizational measures to
        protect your personal data from unauthorized access, disclosure,
        alteration, or destruction.
      </p>

      <h2>7. Cookies</h2>
      <p>
        Our platform uses cookies to enhance your experience. You can manage
        your cookie preferences through your browser settings or by using our
        cookie management tool.
      </p>

      <h2>8. Third-Party Links</h2>
      <p>
        Our platform may contain links to third-party websites. We are not
        responsible for their privacy practices, so we encourage you to review
        their policies.
      </p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Significant changes
        will be communicated to you via email or a notification on our platform.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have questions about this Privacy Policy or wish to exercise your
        rights, please contact us at:
      </p>
      <p>
        Beligent <br />
        Zurich, Switzerland <br />
        Email: <span className="">contact(at)beligent.io</span>
      </p>
    </div>
  </div>
);

export default PrivacyPolicy;
