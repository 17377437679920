import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAtomValue } from "jotai";
import { authAtom } from "../atoms/authAtom.ts";

const AdminProtectedRoutes = () => {
  const { user } = useAtomValue(authAtom);

  if (!user) return <Navigate to={"/auth/login"} />;

  if (user.role === "admin") return <Outlet />;
  else if (user.onboardingStep === "completed") {
    return <Navigate to={"/platform"} />;
  } else return <Navigate to={"/onboarding"} />;
};

export default AdminProtectedRoutes;
