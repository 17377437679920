import "./AdminDashboard.scss";
import React, { useEffect } from "react";
import { useState } from "react";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { toast } from "react-hot-toast";

interface IKycPendingUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  onboardingStep: string;
}

export const AdminDashboard = () => {
  const [kycPendingUsers, setKycPendingUsers] = useState<IKycPendingUser[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchKycUsers();
  }, []);

  const fetchKycUsers = async () => {
    try {
      const res = await axiosClient.get("/admin/kyc-pending-users");
      setKycPendingUsers(res.data.users);
    } catch (error) {
      console.log(error);
    }
  };

  const handleApproveKYC = async (userId: number) => {
    setLoading(true);
    toast.loading("Approving KYC.");
    try {
      await axiosClient.post("/admin/kyc-approved", { userId });
      toast.dismiss();
      toast.success("KYC approved.");
      fetchKycUsers();
    } catch (error) {
      toast.dismiss();
      toast.error("Failed to approve KYC.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="AdminDashboard">
        <h2>Admin Dashboard</h2>
      <div className="kyc-approval-container">
        <div className="kyc-list">
          <h3>Pending KYC Approvals</h3>

          {kycPendingUsers.length === 0 && (
            <div className="no-user">
              <h4> No pending KYC</h4>
            </div>
          )}

          {kycPendingUsers.length !== 0 && (
            <table>
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th className="verify-head">Action</th>
                </tr>
              </thead>
              <tbody>
                {kycPendingUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>
                      {user.firstName} {user.lastName}
                    </td>
                    <td>Pending</td>
                    <td>
                      <button
                        className="verify-button"
                        onClick={() => handleApproveKYC(user.id)}
                        disabled={loading}
                      >
                        Verify
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
