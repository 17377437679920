/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import "./Platform.scss";
import PassportCreation from "../PassportCreation/PassportCreation.tsx";
import Sidebar from "../../../components/Platform/Sidebar/Sidebar.js";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard.tsx";
import PassportCollection from "../PassportCollection/PassportCollection.js";
import UserSettings from "../UserSettings/UserSettings.tsx";
import AddDocument from "../AddDocument/AddDocument.tsx";
import AddEvent from "../AddEvent/AddEvent.tsx";
import PassportSearch from "../../PassportViewer/PassportSearch/PassportSearch.tsx";
import VerifyEmailChange from "../UserSettings/VerifyEmailChange/VerifyEmailChange.tsx";

const menuItems = [
  { title: "Dashboard", path: "/platform/dashboard" },
  // {
  //   title: "Passports",
  //   submenu: [
  //     { title: "Collection", path: "/platform/passports/collection" },
  //     { title: "Creation", path: "/platform/passports/create" },
  //   ],
  // },
  { title: "Manage passports", path: "/platform/passports/collection" },
  { title: "Create new", path: "/platform/passports/create" },
  { title: "User Settings", path: "/platform/user-settings" },
];

const Platform = () => {
  /** ------------[IMP ↓]--------------- */
  // local state is initialized by local storage
  // const [connectedToMintingContract, setConnectedToMintingContract] =
  //     useState(() => {
  //         const saved = localStorage.getItem(
  //             "_BLGT_connectedToMintingContract"
  //         );
  //         return saved ? JSON.parse(saved) : false;
  //     });
  // // Local storage updates when component state is updated
  // useEffect(() => {
  //     localStorage.setItem(
  //         "_BLGT_connectedToMintingContract",
  //         JSON.stringify(connectedToMintingContract)
  //     );
  // }, [connectedToMintingContract]);
  /** ------------[IMP ↑]--------------- */

  function PlatformLayout() {
    return (
      <div className="Platform">
        <div className="platform-container">
          <div className="layout-container">
            <aside className="sidebar">
              <Sidebar menuItems={menuItems} />
            </aside>
            <main className="content">
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Routes>
      <Route element={<PlatformLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/passports/create" element={<PassportCreation />} />
        <Route path="/passports/collection" element={<PassportCollection />} />
        <Route
          path="/passports/passport-details/:address?/:id?"
          element={<PassportSearch />}
        />
        <Route path="/user-settings" element={<UserSettings />} />
        <Route
          path="/user-settings/verify-email-change"
          element={<VerifyEmailChange />}
        />
        <Route path="/passports/add-document" element={<AddDocument />} />
        <Route path="/passports/add-event" element={<AddEvent />} />
        <Route path="*" element={<Navigate to={"/platform/dashboard"} />} />
      </Route>
    </Routes>
  );
};

export default Platform;
