import "./Login.scss";
import React, { FormEvent, useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { validateEmail } from "../../../lib/utils";
import { useSetAtom } from "jotai";
import { loginAtom } from "../../../atoms/authAtom.ts";
import { AxiosError } from "axios";
import { Helmet } from "react-helmet-async";

const Login = () => {
  const navigate = useNavigate();
  const setLogin = useSetAtom(loginAtom);
  /** ---> All states below */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!password || !email) {
      toast.error("Email and password are required!");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Please enter a valid email!");
      return;
    } else if (password.length < 6) {
      toast.error("Password must be minimum 6 characters long!");
      return;
    }

    setLoading(true);

    toast.loading("logging in...");

    try {
      const res = await axiosClient.post("auth/login", {
        email,
        password,
      });

      const data = res.data;
      setLogin({ user: data.user });
      toast.dismiss();
      toast.success("Logged in successfully.");

      /** ---> Redirecting user according to their onboarding step */
      if (data.user?.role === "admin") {
        return navigate("/admin");
      } else if (data.user?.role === "owner") {
        navigate("/platform/dashboard");
      } else if (data.user?.onboardingStep === "completed") {
        navigate("/platform/dashboard");
      } else {
        navigate("/onboarding");
      }

      setEmail("");
      setPassword("");
    } catch (error) {
      toast.dismiss();
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message);
      } else {
        toast.error("Invalid login credentials");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="Login">
      <Helmet>
        <title>Beligent - Login</title>
      </Helmet>
      <div className="hero hero-login gradient-background white-font">
        <div className="text-box">
          <div>
            <h1>Digital Product Passport</h1>
            <h1 className="subtitle">
              Your journey of innovation begins here.
            </h1>
            <hr />
            <p className="main-description">Login and start creating now!</p>
          </div>
        </div>

        {/* ---> instead of multicontainer */}
        <div className="container">
          <h2>Login</h2>
          <form onSubmit={handleLogin} className="form">
            <div className="input-group">
              <span>
                Email *
              </span>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <span>
                Password *
              </span>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <br />
            <button className="btn action-button" disabled={loading}>
              Login
            </button>
            <p id="forgetpass">
              <NavLink to="/auth/forgot-password">Forgot Password? </NavLink>
            </p>
          </form>
          {process.env.NODE_ENV !== "production" && ( // Will be released later
            <p className="no-account">
              (dev) Not having an account?{" "}
              <NavLink to="/auth/register">Register </NavLink>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
