/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ContactButton.scss";

/**
 * ContactButton component.
 * A button that when clicked, copies the email address to the clipboard.
 */
const ContactButton = () => {
  const [imageVisible, setImageVisible] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const emailUser = "contact";
  const emailDomain = "beligent";
  const email = `${emailUser}@${emailDomain}.io`;

  /**
   * Copies the email address to the clipboard.
   * @returns {void}
   */
  const handleCopyEmail = () => {
    setImageVisible(false);
    setTimeout(() => setImageVisible(true), 500);

    navigator.clipboard.writeText(email).then(
      () => {
        setTimeout(() => setShowConfirmation(true), 500);
      },
      (err) => {
        console.error("Could not copy email: ", err);
      }
    );
  };

  return (
    <div className="ContactButton">
      <div className="button-container">
        <div className="block-center">
          <div className="">
            <Link to="/contact" className="btn action-button contact-button">
              Contact us
            </Link>
            {/* <Link to="/contact" className="btn action-button contact-button">
              Contact us
            </Link>
            <button
              type="button"
              onClick={handleCopyEmail}
              className="btn action-button contact-button"
            >
              Contact us
            </button> */}
          </div>
          {/* {showConfirmation && (
            <span className="small-text confirmation">
              Our address {email} has been copied to your clipboard.
            </span>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default ContactButton;
