/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import { Link } from "react-router-dom";
import "./PassportPreview.scss";
import { useAtom } from "jotai";
import { passportPreviewDataAtom } from "../../lib/atoms";

const PassportPreview = ({ passport, showLinks = true }) => {
  const [, setPassportPreviewData] = useAtom(passportPreviewDataAtom);

  const handleClick = () => {
    setPassportPreviewData({
      smartContractAddress: passport?.contractAddress,
      tokenId: passport?.tokenId,
      imageURL: passport?.imageURL,
      passportName: passport?.imageURL,
      passportTimeStamp: passport?.imageURL,
      passportDescription: passport?.imageURL,
    });
  };

  return (
    <div className="PassportPreview">
      <div className="image-card">
        {showLinks ? (
          <Link to="/platform/passports/passport-details/0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045/0">
            <img
              src={passport?.imageURL}
              className="image"
              alt={passport?.passportName}
            />
          </Link>
        ) : (
          <img
            src={passport?.imageURL}
            className="image"
            alt={passport?.passportName}
          />
        )}
      </div>

      <div className="image-details">
        <h3 className="image-title">Name: {passport?.passportName}</h3>
        <p className="image-description">
          Description: {passport?.passportDescription}
        </p>
        <p className="image-date">
          Created: {new Date(passport?.passportTimeStamp).toLocaleString()}
        </p>
        {showLinks && (
          <div className="image-actions">
            <button className="action-btn">
              <Link to="/platform/passports/passport-details/0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045/0">
                <img className="icon" src="/search.svg" alt="search-icon" title="View the passport details" />
              </Link>
            </button>
            <button className="action-btn">
              <Link to={`/platform/passports/add-document`}>
                <img
                  className="icon"
                  src="/add-document.svg"
                  alt="search-icon"
                  title="Associate a document with your digital passport" 
                />
              </Link>
            </button>
            <button className="action-btn">
              <Link to="/platform/passports/add-event/" onClick={handleClick}>
                <img className="icon" src="/event.svg" alt="search-icon" title="Add an event to your digital passport" />
              </Link>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PassportPreview;
