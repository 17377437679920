import { atom } from "jotai";

import { IUser } from "../interfaces/IUser";

interface IInitialAuthState {
  user: IUser | null;
  timestamp: string | null;
}

const saveUserToSession = (user: IUser, timestamp: string) => {
  localStorage.setItem("_BLGT_user", JSON.stringify(user));
  localStorage.setItem("_BLGT_userTimestamp", timestamp);
};

const checkSessionTimeout = (): IInitialAuthState => {
  const storedUser = localStorage.getItem("_BLGT_user");
  const storedTimestamp = localStorage.getItem("_BLGT_userTimestamp");

  if (!storedUser || !storedTimestamp) {
    return { user: null, timestamp: null };
  }

  const currentTime = new Date().getTime();
  const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

  if (currentTime - parseInt(storedTimestamp) > SEVEN_DAYS) {
    localStorage.removeItem("_BLGT_user");
    localStorage.removeItem("_BLGT_userTimestamp");
    console.log("Session expired");
    return { user: null, timestamp: null };
  }

  return { user: JSON.parse(storedUser), timestamp: storedTimestamp };
};

export const authAtom = atom<IInitialAuthState>(checkSessionTimeout());

export const loginAtom = atom(null, (get, set, { user }: { user: IUser }) => {
  const timestamp = new Date().getTime().toString();
  saveUserToSession(user, timestamp);
  set(authAtom, { user, timestamp });
});

export const logoutAtom = atom(null, (get, set) => {
  set(authAtom, { user: null, timestamp: null });
  localStorage.removeItem("_BLGT_user");
  localStorage.removeItem("_BLGT_userTimestamp");
});

export const updateUserAtom = atom(
  null,
  (get, set, updatedUser: Partial<IUser>) => {
    const authState = get(authAtom);

    if (!authState.user || !authState.timestamp) return;

    const newUser = { ...authState.user, ...updatedUser };
    saveUserToSession(newUser, authState.timestamp);
    set(authAtom, { user: newUser, timestamp: authState.timestamp });
  }
);
