/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useState } from "react"; 
import './PassportSection.scss';
import { camelCaseToNormalText } from "../../../lib/utils";

interface PassportSectionProps {
  sectionData?: Record<string, any>;
  sectionTitle: string;
  defaultOpen?: boolean; 
}


const PassportSection: React.FC<PassportSectionProps> = ({ sectionData, sectionTitle, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen ?? false);

  if (!sectionData || Object.keys(sectionData).length === 0) {
    return null; // Hide section if empty
  }

  const toggleSection = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="accordion" id={`accordion-${sectionTitle}`}>
      <div className="accordion-item">
        <h2 className="accordion-header" id={`heading-${sectionTitle}`}>
          <button
            className={`accordion-button ${isOpen ? "" : "collapsed"}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse-${sectionTitle}`}
            aria-controls={`collapse-${sectionTitle}`}
            onClick={toggleSection}
          >
            {sectionTitle}
          </button>
        </h2>
        <div
          id={`collapse-${sectionTitle}`}
          className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
          aria-labelledby={`heading-${sectionTitle}`}
          data-bs-parent={`#accordion-${sectionTitle}`}
        >
          <div className="accordion-body">
            {Object.entries(sectionData)
              .filter(([, value]) => typeof value === "string" || typeof value === "number")
              .map(([key, value]) => (
                <div className="row mb-2" key={key}>
                  <div className="col-5 text-muted">{camelCaseToNormalText(key)}:</div>
                  <div className="col-7">{value || "N/A"}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassportSection;
