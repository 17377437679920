/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useState } from "react";
import "./LoginButton.scss";
import { useNavigate } from "react-router-dom";
import { useAtomValue, useSetAtom } from "jotai";
import { authAtom, logoutAtom } from "../../../atoms/authAtom.ts";

const LoginButton = () => {
  const { user } = useAtomValue(authAtom);
  const setLogout = useSetAtom(logoutAtom);
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    const saved = localStorage.getItem("_BLGT_user");
    if (!user) {
      setIsPopupOpen(!isPopupOpen);
    } else {
      // Todo: Logout
    }
  };

  const handleLogout = () => {
    setLogout();
    navigate("/auth/login");
  };

  return (
    <div className="LoginButton">
      <div className="block-center">
        <div className="">
          {user ? (
            <div>
              <button onClick={handleLogout} className="btn action-button">
                Logout
              </button>
            </div>
          ) : (
            <div>
              <button onClick={togglePopup} className="btn action-button">
                Login
              </button>
            </div>
          )}

          {/* {isPopupOpen && (
            <LoginPopup closePopup={togglePopup} recordUser={recordUser} />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default LoginButton;
