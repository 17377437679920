/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";

import "./Sidebar.scss";
import { NavLink, useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { authAtom } from "../../../atoms/authAtom.ts";

const Sidebar = ({ menuItems }) => {
  const location = useLocation();
  const { user } = useAtomValue(authAtom);

  const menuItemsToShow = menuItems.filter((item) =>
    user?.role === "owner" ? item.path !== "/platform/passports/create" : item
  );

  const renderMenuItems = (items) => {
    return items.map((item, index) => {
      if (item.submenu) {
        return (
          <div key={index} className="">
            <div className="menu-item nav-item no-hover just-title">
              {item.title}:{" "}
            </div>
            <div className="submenu">{renderMenuItems(item.submenu)}</div>
          </div>
        );
      } else {
        return (
          <NavLink
            to={item.path}
            key={index}
            className={
              location.pathname === item.path
                ? "menu-item nav-item active"
                : "menu-item nav-item"
            }
          >
            <div className="">{item.title}</div>
          </NavLink>
        );
      }
    });
  };

  return (
    <div className="Sidebar">
      {!menuItems || menuItems.length === 0 ? (
        <div className="warning">No menu items available</div>
      ) : (
        renderMenuItems(menuItemsToShow)
      )}
    </div>
  );
  // return (
  //     <div className="Sidebar">
  //     {!menuItems || menuItems.length === 0 ? (
  //         <div className="warning">No menu items available</div>
  //     ) : (
  //         menuItems.map((item, index) => (
  //             <NavLink
  //                 to={item.path}
  //                 key={index}
  //                 className={
  //                     location.pathname === "/platform" + item.path
  //                         ? "menu-item nav-item active"
  //                         : "menu-item nav-item"
  //                 }
  //             >
  //                 <div className="icon">{item.icon}</div>
  //                 <div className="title">{item.title}</div>
  //             </NavLink>
  //             ))
  //         )}
  //     </div>
  // );
};

{
  /* <NavLink to="page1" activeClassName="active">
Page 1
</NavLink> */
}

export default Sidebar;
