/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import "./AppFooter.scss";
import { NavLink } from "react-router-dom";
import { useAtomValue } from "jotai";
import { authAtom } from "../../atoms/authAtom.ts";

const AppFooter = () => {
  const { user } = useAtomValue(authAtom);
  return (
    <footer className="AppFooter">
      <div className="normal-layout">
        <div className="footer-left">
          <div className="text section">
            <span className="bold">Beligent </span> ©2025
            <br />
            Zurich, Switzerland
          </div>

          <div className="logo-container">
            <img src="/logo-small.png" className="img-fluid " alt="Beligent" />
          </div>
        </div>
        <div className="vertical-bar"></div>
        <div className="footer-right">
          <div className="section">
            <h4>Sitemap</h4>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "nav-item nav-link active" : "nav-item nav-link"
              }
            >
              Main Site
            </NavLink>
            {process.env.NODE_ENV !== "production" && ( // Will be released later
              <NavLink
                to="/passport"
                className={({ isActive }) =>
                  isActive ? "nav-item nav-link active" : "nav-item nav-link"
                }
              >
                (dev) Passport viewer
              </NavLink>
            )}
            <NavLink
              to="/platform"
              className={({ isActive }) =>
                isActive ? "nav-item nav-link active" : "nav-item nav-link"
              }
            >
              Platform
            </NavLink>
            {process.env.NODE_ENV !== "production" && ( // Will be released later
              <NavLink
                to="/documentation"
                className={({ isActive }) =>
                  isActive ? "nav-item nav-link active" : "nav-item nav-link"
                }
              >
                (dev) Documentation
              </NavLink>
            )}
            {user && user.role === "admin" && (
              <NavLink
                to="/admin"
                className={({ isActive }) =>
                  isActive ? "nav-item nav-link active" : "nav-item nav-link"
                }
              >
                Admin
              </NavLink>
            )}
          </div>
          <div className="section">
            <h4>Legal</h4>
            {/* {process.env.NODE_ENV !== "production" && ( // Will be released later
              <NavLink
                to="/impressum"
                className={({ isActive }) =>
                  isActive ? "nav-item nav-link active" : "nav-item nav-link"
                }
              >
                (dev) Impressum
              </NavLink>
            )} */}
            <NavLink
              to="/terms-of-use"
              className={({ isActive }) =>
                isActive ? "nav-item nav-link active" : "nav-item nav-link"
              }
            >
              Terms of Use
            </NavLink>
            <NavLink
              to="/privacy-policy"
              className={({ isActive }) =>
                isActive ? "nav-item nav-link active" : "nav-item nav-link"
              }
            >
              Privacy Policy
            </NavLink>
          </div>
          <div className="section">
            <h4>Contact</h4>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? "nav-item nav-link active" : "nav-item nav-link"
              }
            >
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
