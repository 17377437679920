/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { ChangeEvent, FormEvent, useState } from "react";
import "./RegistrationEnd.scss";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { useSetAtom } from "jotai";
import { authAtom, updateUserAtom } from "../../../atoms/authAtom.ts";
import { useAtomValue } from "jotai/react";
import { Helmet } from "react-helmet-async";

const initialFormData = {
  firstName: "",
  lastName: "",
  birthDate: "",
  nationality: "",
  streetName: "",
  streetNumber: "",
  postCode: "",
  city: "",
  country: "",
  region: "",
};

const initialCompanyData = {
  companyName: "",
  companyWebsiteUrl: "",
  companyRegistrationNumber: "",
  companyRole: "",
  companyAddressIsSame: false,
  companyStreetName: "",
  companyStreetNumber: "",
  companyPostCode: "",
  companyCity: "",
  companyCountry: "",
  companyRegion: "",
};

type TRole = "creator" | "owner";

const RegistrationEnd = () => {
  const navigate = useNavigate();
  const { user } = useAtomValue(authAtom);
  const setUpdatedUser = useSetAtom(updateUserAtom);
  const [formData, setFormData] = useState(initialFormData);
  const [companyData, setCompanyData] = useState(initialCompanyData);
  const [role, setRole] = useState<TRole>("owner");

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCompanyInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setCompanyData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.firstName) {
      toast.error("First name is required!");
      return false;
    }
    if (!formData.lastName) {
      toast.error("Last name is required!");
      return false;
    }
    if (!formData.birthDate) {
      toast.error("Date of birth is required!");
      return false;
    }
    if (!formData.nationality) {
      toast.error("Nationality is required!");
      return false;
    }
    if (!formData.streetName) {
      toast.error("Street name is required!");
      return false;
    }
    if (!formData.streetNumber) {
      toast.error("Street number is required!");
      return false;
    }
    if (!formData.city) {
      toast.error("City is required!");
      return false;
    }
    if (!formData.postCode) {
      toast.error("Postal code is required!");
      return false;
    }
    if (!formData.country) {
      toast.error("Country is required!");
      return false;
    }
    if (role === "creator") {
      if (!companyData.companyName) {
        toast.error("The company's official name is required!");
        return false;
      }
      if (!companyData.companyWebsiteUrl) {
        toast.error("Professional social media Url is required!");
        return false;
      }
      if (!companyData.companyRole) {
        toast.error("Your position is required!");
        return false;
      }

      // Validate company address if it's not the same as personal address
      if (!companyData.companyAddressIsSame) {
        if (!companyData.companyStreetName) {
          toast.error("Company street name is required!");
          return false;
        }
        if (!companyData.companyStreetNumber) {
          toast.error("Company street number is required!");
          return false;
        }
        if (!companyData.companyCity) {
          toast.error("Company city is required!");
          return false;
        }
        if (!companyData.companyPostCode) {
          toast.error("Company postal code is required!");
          return false;
        }
        if (!companyData.companyCountry) {
          toast.error("Company country is required!");
          return false;
        }
      }
    }

    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (role === "creator") {
      if (!validateForm()) return;
    }
    // ##############################################
    // ##############################################
    // ### [TODO] : Need to check functionlity ######
    // ##############################################
    // ##############################################

    console.log("role 👉👉👉", role);

    try {
      await axiosClient.post("/auth/register", {
        ...formData,
        role,
        ...(role === "owner" ? {} : { ...companyData }),
      });
      toast.success("User updated successfully.");
      const step = user?.onboardingStep;
      const newStep = step === "register-end" ? "kyc" : step;
      setUpdatedUser({
        firstName: formData.firstName,
        lastName: formData.lastName,
        onboardingStep: newStep,
        role,
      });
      navigate("/onboarding/kyc-verification", { replace: true });
    } catch (error) {
      toast.error("Failed to update user!");
      console.log(error);
    }
  };

  return (
    <div className="RegistrationEnd ">
      <div className="hero gradient-background ">
        <Helmet>
          <title>Beligent - Registration</title>
        </Helmet>
        <div className="page-header">
          <h2>Step 2/5: Registration</h2>
          <p>Please, finish the registration by completing the fields below.</p>
          <p>Make sure all information is accurate for KYC verification.</p>
        </div>

        <div className="container">
          <div className="end-registration-form">
            <form onSubmit={handleSubmit} className="form">
              <br />
              <h3>Your Information</h3>
              <br />

              <div className="input-group">
                <label htmlFor="role">Account Type *</label>

                <select
                  name="role"
                  id="role"
                  className="input"
                  value={role}
                  onChange={(e) => setRole(e.target.value as TRole)}
                >
                  <option value="creator">Creator</option>
                  <option value="owner">Owner</option>
                </select>
              </div>

              <div className="input-group">
                <label htmlFor="firstName">First Name *</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="input-group">
                <label htmlFor="lastName">Last Name *</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="input-group">
                <label htmlFor="birthDate">Date of Birth *</label>
                <input
                  type="date"
                  id="birthDate"
                  name="birthDate"
                  value={formData.birthDate}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="input-group">
                <label htmlFor="nationality">Nationality *</label>
                <input
                  type="text"
                  id="nationality"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="input-group">
                <label htmlFor="streetName">Street Name *</label>
                <input
                  type="text"
                  id="streetName"
                  name="streetName"
                  value={formData.streetName}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="input-group">
                <label htmlFor="streetNumber">Street Number *</label>
                <input
                  type="text"
                  id="streetNumber"
                  name="streetNumber"
                  value={formData.streetNumber}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="input-group">
                <label htmlFor="postCode">Postal Code *</label>
                <input
                  type="text"
                  id="postCode"
                  name="postCode"
                  value={formData.postCode}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="input-group">
                <label htmlFor="city">City *</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="input-group">
                <label htmlFor="region">Region</label>
                <input
                  type="text"
                  id="region"
                  name="region"
                  value={formData.region}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>

              <div className="input-group">
                <label htmlFor="country">Country *</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  className="input"
                />
              </div>
              <br />

              {role === "creator" && (
                <>
                  <h3>Company Information</h3>
                  <br />
                  <div className="input-group">
                    <label htmlFor="companyName">Company Name *</label>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      value={companyData.companyName}
                      onChange={handleCompanyInputChange}
                      className="input"
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="companyWebsiteUrl">Company website *</label>
                    <input
                      type="text"
                      id="companyWebsiteUrl"
                      name="companyWebsiteUrl"
                      value={companyData.companyWebsiteUrl}
                      onChange={handleCompanyInputChange}
                      className="input"
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="companyRole">Your Position *</label>
                    <input
                      type="text"
                      id="companyRole"
                      name="companyRole"
                      value={companyData.companyRole}
                      onChange={handleCompanyInputChange}
                      className="input"
                    />
                  </div>

                  <div className="check-group">
                    <input
                      type="checkbox"
                      id="companyAddressIsSame"
                      name="companyAddressIsSame"
                      checked={companyData.companyAddressIsSame}
                      onChange={handleCompanyInputChange}
                      className="check-input"
                    />
                    <label htmlFor="companyAddressIsSame">
                      The Company Address is the Same *
                    </label>
                  </div>

                  {!companyData.companyAddressIsSame && (
                    <>
                      <div className="input-group">
                        <label htmlFor="companyStreetName">
                          Company Street Name *
                        </label>
                        <input
                          type="text"
                          id="companyStreetName"
                          name="companyStreetName"
                          value={companyData.companyStreetName}
                          onChange={handleCompanyInputChange}
                          className="input"
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="companyStreetNumber">
                          Company Street Number *
                        </label>
                        <input
                          type="text"
                          id="companyStreetNumber"
                          name="companyStreetNumber"
                          value={companyData.companyStreetNumber}
                          onChange={handleCompanyInputChange}
                          className="input"
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="companyPostCode">
                          Company Postal Code *
                        </label>
                        <input
                          type="text"
                          id="companyPostCode"
                          name="companyPostCode"
                          value={companyData.companyPostCode}
                          onChange={handleCompanyInputChange}
                          className="input"
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="companyCity">Company City *</label>
                        <input
                          type="text"
                          id="companyCity"
                          name="companyCity"
                          value={companyData.companyCity}
                          onChange={handleCompanyInputChange}
                          className="input"
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="companyRegion">Company Region</label>
                        <input
                          type="text"
                          id="companyRegion"
                          name="companyRegion"
                          value={companyData.companyRegion}
                          onChange={handleCompanyInputChange}
                          className="input"
                        />
                      </div>

                      <div className="input-group">
                        <label htmlFor="companyCountry">
                          Company Country *
                        </label>
                        <input
                          type="text"
                          id="companyCountry"
                          name="companyCountry"
                          value={companyData.companyCountry}
                          onChange={handleCompanyInputChange}
                          className="input"
                        />
                      </div>
                    </>
                  )}
                </>
              )}

              <div>
                <button type="submit" className="btn action-button">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationEnd;
