/**
 * Beligent
 * Copyright 2024
 */

import React, { useEffect } from "react";
import "./App.scss";
import {
  Routes,
  Route,
  Outlet,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";

import AppHeader from "./components/AppHeader/AppHeader.tsx";
import AppFooter from "./components/AppFooter/AppFooter.js";
import HomePage from "./pages/HomePage/HomePage.tsx";
import PassportSearch from "./pages/PassportViewer/PassportSearch/PassportSearch.tsx";
import Platform from "./pages/platform/Platform/Platform.tsx";
import Solutions from "./pages/Solutions/Solutions.js";
import CaseStudy from "./pages/CaseStudy/CaseStudy.js";
import About from "./pages/About/About.js";
import ContactUs from "./pages/ContactUs/ContactUs.tsx";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse.js";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy.js";
import Impressum from "./pages/Impressum/Impressum.js";
import Onboarding from "./pages/userOnboarding/Onboarding/Onboarding.tsx";
import ScrollToTop from "./lib/ScrollToTop.js";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail.tsx";
import PlatformProtectedRoutes from "./hoc/PlatformProtectedRoutes.tsx";
import Login from "./pages/auth/Login/Login.tsx";
import ForgotPassword from "./pages/auth/ForgotPassword/ForgotPassword.tsx";
import ResetPassword from "./pages/auth/ResetPassword/ResetPassword.tsx";
import OnboardingProtectedRoutes from "./hoc/OnboardingProtectedRoutes.tsx";
import AuthRoutes from "./hoc/AuthRoutes.tsx";
import Register from "./pages/auth/Register/Register.tsx";
import { axiosClient, initializeAxiosInterceptor } from "./lib/axiosClient.ts";
import { useSetAtom } from "jotai";
import { logoutAtom } from "./atoms/authAtom.ts";
import AdminProtectedRoutes from "./hoc/AdminProtectedRoutes.tsx";
import { AdminDashboard } from "./pages/Admin/AdminDashboard/AdminDashboard.tsx";

function App() {
  const navigate = useNavigate();
  const setLogout = useSetAtom(logoutAtom);

  /** ---> Initializing axios res interceptor with navigate. */
  useEffect(() => {
    const interceptor = initializeAxiosInterceptor(navigate, setLogout);
    return () => {
      axiosClient.interceptors.response.eject(interceptor);
    };
  }, [navigate, setLogout]);
  initializeAxiosInterceptor(navigate, setLogout);

  /** ------------[IMP ↓]--------------- */
  // This is temporary as we don't retrieve the user's nfts on the blockchain.
  // We assume it is empty if page reloaded.
  // probably not used anymore but could be useful.
  // localStorage.setItem('_BLGT_minting', JSON.stringify(false));
  // localStorage.setItem('_BLGT_minted', JSON.stringify(false));
  /** ------------[IMP ↑]--------------- */

  function Layout() {
    return (
      <div className="app-container">
        <ScrollToTop />
        <AppHeader />
        <Outlet />
        <AppFooter />
      </div>
    );
  }

  return (
    <div className="App">
      <Toaster />
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* ---> Public routes. */}
          <Route index element={<HomePage />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/case-study" element={<CaseStudy />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/passport/:address?/:id?" element={<PassportSearch />} />

          {/* ---> Auth routes. */}
          <Route path="/auth" element={<AuthRoutes />}>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/auth/login" />} />
          </Route>

          {/* ---> Platform routes. */}
          <Route element={<PlatformProtectedRoutes />}>
            <Route path="/platform/*" element={<Platform />} />
            <Route
              path="/platform"
              element={<Navigate to="/platform/dashboard" />}
            />
          </Route>

          {/* ---> Onboarding routes. */}
          <Route element={<OnboardingProtectedRoutes />}>
            <Route path="/onboarding/*" element={<Onboarding />} />
            <Route path="/onboarding" element={<Navigate to="/onboarding" />} />
          </Route>

          {/* ---> Admin routes. */}
          <Route path="/admin" element={<AdminProtectedRoutes />}>
            <Route index element={<AdminDashboard />} />
          </Route>

          {/* ---> 404 (Not Found) route. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

function NoMatch() {
  return (
    <div className="screen-404">
      <h1 className="">Page not found</h1>
      <p>
        <Link to="/">Back to home page</Link>
      </p>
    </div>
  );
}
