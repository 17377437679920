/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";

import "./CaseStudy.scss";
import ContactButton from "../../components/ContactButton/ContactButton";
import { Helmet } from "react-helmet-async";

const CaseStudy = () => {
  return (
    <div className="CaseStudy">
      <Helmet>
        <title>Beligent - Case Study</title>
      </Helmet>
      {/* ---> Top Hero Section */}
      <div className="hero gradient-background white-font">
        <div className="text-box">
          <img
            src="./luthier-logo.png"
            alt="luthier-logo"
            className="luthier-logo"
          />
          <h1>
            Alessandro Milani - <br /> Master Luthier
          </h1>
          <hr />
          <p className="main-description">
            Alessandro has built a reputation for exquisite craftsmanship,
            specializing in the creation and restoration of violins, violas, and
            cellos. Recognizing the growing need for secure authenticity
            verification, he envisioned a digital certification system to
            protect the integrity of his instruments.
          </p>

          <div className="contact-button">
            <ContactButton />
          </div>
        </div>

        <div className="certified-container">
          <img src="./certified.png" className="certified-img" />
        </div>
        <div className="violin-container">
          <img src="./violin.png" className="img-fluid unblur" />
        </div>
      </div>
      {/* ---> Context box */}
      <div className="context-box-outer">
        <div className="context-box-inner wite-font">
          <h2>Context</h2>
          <p>
            <a
              href="https://alessandromilaniliutaio.com/en/about-me"
              alt="Alessandro Milani's website"
              className="white-font"
              target="_blank"
            >
              Alessandro Milani
            </a>{" "}
            is a renowned master luthier based near Cremona, the birthplace of
            the legendary Stradivarius. In addition to his craftsmanship, he has
            contributed several innovations to the industry through his passion
            for electronics.
            <br />
            Today, he collaborates with Beligent to develop the most advanced
            certification technology, seamlessly integrating it into his
            instruments. By incorporating NFC technology into his violins, he
            connects each handcrafted instrument to blockchain records. This
            ensures secure identification and enables extended services with a
            simple tap.
          </p>
        </div>
      </div>
      {/* ---> Points */}
      <div className="points-section">
        <div className="point-box">
          <h3>1. Strategic Advice</h3>
          <p>
            He provided expert guidance on integrating NFC technology without
            compromising the instrument's acoustics. Additionally, he curated a
            selection of high-quality NFC tags based on key factors:
          </p>
          <ul className="unnumbered-list">
            <li>
              Aesthetic : Not affecting the elegant design of the instrument.
            </li>
            <li>Accoustic : Preserving sound quality and resonance.</li>
            <li>Security : Preventing unauthorized modifications.</li>
            <li>Durability: Ensuring longevity within the instrument.</li>
          </ul>
        </div>
        <div className="point-box">
          <h3>2. NFC Tag Integration</h3>
          <p>
            Each NFC tag is securely encoded and discreetly integrated into the
            instrument. Its placement was carefully selected to remain
            unobtrusive while ensuring full functionality.
          </p>
        </div>
        <div className="point-box">
          <h3>3. Seamless Certification</h3>
          <p>
            Creators can enhance trust and traceability while respecting GDPR
            requirements. Owner's information remain private and are under
            control. The detailed digital passport contains important
            information and documents, including:
          </p>
          <ul className="unnumbered-list">
            <li>Authenticity information</li>
            <li>Creator identity</li>
            <li>Proof of authenticity</li>
            <li>Proof of ownership</li>
            <li>Certifications</li>
            <li>History</li>
            <li>Additional notes on materials, sustainability and maintenance</li>
          </ul>
        </div>
        <div className="point-box">
          <h3>4. Effortless verification Process</h3>
          <p>With the embedded NFC tag, verification is as simple as a tap:</p>
          <ul className="unnumbered-list">
            <li>
              Owners and buyers can scan the violin using a smartphone to access
              its certificate online.
            </li>
            <li>
              Instant authentication ensures the violin's history and
              craftsmanship remain transparent and secure.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CaseStudy;
