import React, { useEffect, useState } from "react";
import { IUserProfile } from "../../../interfaces/IUserProfile";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { Navigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { updateUserAtom } from "../../../atoms/authAtom.ts";

const KycPending = () => {
  const setUpdatedUser = useSetAtom(updateUserAtom);
  const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);

  /** --> Fetching user info onload. */
  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const res = await axiosClient.get("/auth/profile");
      setUserProfile(res.data.userProfile);
    } catch (error) {
      console.log(error);
    }
  };

  if (userProfile?.onboardingStep === "completed") {
    setUpdatedUser({
      onboardingStep: "completed",
    });
    return <Navigate to={"/platform"} replace />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "60vh",
      }}
    >
      <div>
        <h1>Your KYC Verification is Pending.</h1>
        <p>
          Once team beligent approved your kyc then you can access the platform
        </p>
      </div>
    </div>
  );
};

export default KycPending;
