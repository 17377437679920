/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { FormEvent, useState } from "react";
import "./AddEvent.scss";
import { useAtom } from "jotai";
import { passportPreviewDataAtom } from "../../../lib/atoms";
import PassportPreview from "../../PassportPreview/PassportPreview.js";
import toast from "react-hot-toast";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { Helmet } from "react-helmet-async";

const AddEvent = () => {
  const [eventType, setEventType] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [passportPreviewData] = useAtom(passportPreviewDataAtom);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axiosClient.post("/passports/add-event", {
        eventName,
        eventType,
        eventDate,
      });
      toast.success("Event created successfully.");
      setEventType("");
      setEventName("");
      setEventDate("");
    } catch (error) {
      console.log(error);
      toast.error("Event creation failed.");
    }
  };

  return (
    <div className="AddEvent">
      <Helmet>
        <title>Beligent - Add Event</title>
      </Helmet>
      <h1>Add an Event</h1>
      <p>
        You can add an event to your asset. It will be recorded on blockchain so
        make sure that you provide true and accurate information.
      </p>

      <div className="passport-container">
        <PassportPreview passport={passportPreviewData} showLinks={false} />
      </div>

      {/* ---> Form */}
      <h3>Define the Event</h3>
      <form onSubmit={handleSubmit} className="form">
        <div className="inp-wrapper">
          <label htmlFor="eventType">Event Type</label>
          <input
            type="text"
            name="eventType"
            id="eventType"
            required
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
          />
        </div>
        <div className="inp-wrapper">
          <label htmlFor="eventName">Event Name</label>
          <input
            type="text"
            name="eventName"
            id="eventName"
            required
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
          />
        </div>
        <div className="inp-wrapper">
          <label htmlFor="eventDate">Event Date</label>
          <input
            type="date"
            name="eventDate"
            id="eventDate"
            required
            value={eventDate}
            onChange={(e) => setEventDate(e.target.value)}
          />
        </div>

        <div>
          <button className="btn action-button mint-button">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default AddEvent;
