import React, { FC } from "react";
import { useDropzone } from "react-dropzone";
import { Upload } from "lucide-react";
import "./DropZone.scss";

type FileUploadProps = {
  onFilesSelected: (files: File[]) => void;
  acceptedTypes?: Record<string, string[]>;
  multiple?: boolean;
};

const DropZone: FC<FileUploadProps> = ({
  onFilesSelected,
  acceptedTypes = { "*": [] },
  multiple = false,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFilesSelected,
    accept: acceptedTypes,
    multiple,
  });

  return (
    <div className="DropZone">
      <div className="upload-container">
        <div
          {...getRootProps()}
          className={`drop-zone ${isDragActive ? "active" : ""}`}
        >
          <input {...getInputProps()} />
          <Upload className="icon" size={50} />
          <p>
            Drag & drop files here, or{" "}
            <span className="browse-text">click to browse</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DropZone;
