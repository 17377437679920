/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import axios from "axios";

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_URL_ENDPOINTS,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const initializeAxiosInterceptor = (
  navigate: (path: string) => void,
  setLogout: () => void
) => {
  return axiosClient.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response && error.response.status === 401) {
        const requestUrl = error.config.url;

        if (requestUrl?.includes("auth/login")) {
          return Promise.reject(error);
        }

        setLogout();
        navigate("/auth/login");
      }
      return Promise.reject(error);
    }
  );
};
