import "./ForgotPassword.scss";
import React, { FormEvent, useState } from "react";
import toast from "react-hot-toast";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { validateEmail } from "../../../lib/utils";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ForgotPassword = () => {
  const navigate = useNavigate();
  /** ---> All states below */
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email!");
      return;
    }
    setLoading(true);

    toast.loading("Sending reset password email.");

    try {
      const res = await axiosClient.post("auth/forgot-password", {
        email,
      });

      toast.dismiss();
      setEmail("");
      toast.success(res.data.message);
      navigate("/auth/login");
    } catch (error) {
      toast.dismiss();
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message);
      } else {
        toast.error("Invalid email!");
      }
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ForgotPassword hero gradient-background white-font">
      <Helmet>
        <title>Beligent - Forget Password</title>
      </Helmet>
      <div className="text-box">
        <div>
          <h1>Digital Product Passport</h1>
          <h1 className="subtitle">Your journey of innovation begins here.</h1>
          <hr />
          <p className="main-description">
            Enter your email to reset your passport !
          </p>
          <p className="">
            You'll receive an email with further instructions to set a new
            password.
          </p>
        </div>
      </div>

      {/* ---> instead of multicontainer */}
      <div className="container">
        <h2 className="heading">Forgot Password</h2>
        <form onSubmit={handleForgotPassword} className="form">
          <div className="input-group">
            <span>
              Email *
            </span>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <br />
          <button className="btn action-button" disabled={loading}>
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
