/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useState, useEffect } from "react";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { useMatch, useParams } from "react-router-dom";
import "./PassportViewer.scss";
import ImageSliderWrapper from "../../../components/PassportViewer/ImageSlider/ImageSlider.js";
import PassportSection from "../../../components/PassportViewer/PassportSection/PassportSection.tsx";
import { Helmet } from "react-helmet-async";

// Define TypeScript interfaces for passport data structure
interface PassportMetadata {
  smartContractAddress?: string;
  tokenId?: string;
  blockchain?: string;
  issuerAddress?: string;
  manufacturerAddress?: string;
  creationTimestamp?: string;
  passportURL?: string;
  passportMetadataURL?: string;
  blockchainExplorerURL?: string;
  openSeaURL?: string;
  passportImageURL?: string;
}

interface FullDescription {
  materials?: Record<string, string>; // Adjust based on actual API response
  measurements?: Record<string, string>;
}

interface Images {
  description?: string;
  fileName?: string;
  timestamp?: string;
  location?: string;
}

interface PassportInfo {
  passportMetadata?: PassportMetadata;
  instrumentType?: string;
  description?: string;
  name?: string;
  fullDescription?: FullDescription;
  yearOfCreation?: string;
  placeOfCreation?: string;
  manufacturer?: string;
  model?: string;
  images?: Images[];
}

interface PassportViewerProps {
  resultObject?: any; // If necessary, replace `any` with a more specific type
}

const PassportViewer: React.FC<PassportViewerProps> = ({ resultObject }) => {
  // console.log("resultObject");
  // console.log(resultObject); empty object

  const [passportInfo, setPassportInfo] = useState<PassportInfo>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const match = useMatch("/platform/passports/passport-details/:address?/:id?");
  const platformPassport = !!match;

  const { address = "", id = "" } = useParams<{
    address?: string;
    id?: string;
  }>();

  useEffect(() => {
    const fetchPassport = async () => {
      try {
        const response = await axiosClient.get(`/passport-public`);

        if (!response || response.status !== 200) {
          throw new Error("Failed to fetch images");
        }

        const passportData: PassportInfo = response.data || {};
        setPassportInfo(passportData);
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };
    fetchPassport();
  }, []);

  const ImageData = passportInfo.images?.map((image) => ({
    original: image.location,
    thumbnail: image.location,
    fileName: image.fileName,
    imageDescription: image.description,
    timestamp: image.timestamp,
  }));

  const renderImage = () => {
    return (
      <div className="passport-img">
        {passportInfo.passportMetadata?.passportImageURL && (
          <img
            src={passportInfo.passportMetadata.passportImageURL}
            alt="Passport"
          />
        )}
      </div>
    );
  };

  return (
    <div className="PassportViewer">
      <Helmet>
        <title>Beligent - {platformPassport ? "Passport Details" : "Passport"}</title>
      </Helmet>
      <div className="report-container">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="error">{error}</p>
        ) : Object.keys(passportInfo).length !== 0 ? (
          <>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1
                    style={platformPassport ? { fontSize: "40px" } : undefined}
                    className="center"
                  >
                    {passportInfo.name}
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">{renderImage()}</div>
                <div className="col-md-7">
                  <PassportSection
                    sectionData={passportInfo}
                    sectionTitle="General Information"
                    defaultOpen={true}
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <PassportSection
                    sectionData={passportInfo.fullDescription?.materials}
                    sectionTitle="Materials"
                    defaultOpen={true}
                  />
                </div>
                <div className="col-md-6">
                  <PassportSection
                    sectionData={passportInfo.fullDescription?.measurements}
                    sectionTitle="Measurements"
                    defaultOpen={true}
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <PassportSection
                    sectionData={passportInfo?.compositionAndOrigin}
                    sectionTitle="Composition and origin"
                    defaultOpen={false}
                  />
                </div>
                <div className="col-md-6">
                  <PassportSection
                    sectionData={passportInfo?.documents}
                    sectionTitle="Documents"
                    defaultOpen={false}
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <ImageSliderWrapper image={ImageData} />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <PassportSection
                    sectionData={passportInfo?.passportMetadata}
                    sectionTitle="Passport Metadata"
                    defaultOpen={true}
                  />
                </div>
                <div className="col-md-6">
                  <PassportSection
                    sectionData={passportInfo?.maintenance}
                    sectionTitle="Documents"
                    defaultOpen={true}
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <PassportSection
                    sectionData={passportInfo?.history}
                    sectionTitle="History"
                    defaultOpen={false}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PassportViewer;
