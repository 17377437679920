/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";

import "./PassportSearch.scss";
import PassportViewer from "../PassportViewer/PassportViewer.tsx";
import { useLocation, useParams } from "react-router-dom";
import { useAtomValue } from "jotai";
import { authAtom } from "../../../atoms/authAtom.ts";
import { Helmet } from "react-helmet-async";

const HTTPS_PROTOCOL_PREFIX = "https://";
const publicKeyVitalik = "0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045";

const PassportSearch = () => {
  const { user } = useAtomValue(authAtom);
  /** ---> All states. */
  let { smartContractAddress, tokenId } = useParams();

  const location = useLocation();

  const { address = "", id = "" } = useParams<{
    address?: string;
    id?: string;
  }>();

  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);

  const renderContent = () => {
    if (!!address && !!id) {
      const resultObject = {};
      return <PassportViewer resultObject={resultObject} />;
    }

    return (
      <div className="global-container">
        <Helmet>
          <title>Beligent - Passport Search</title>
        </Helmet>
        <div className="centered-container hero gradient-background">
          <h1>Check your product or asset</h1>
          <p>
            Simply scan your product with your telephone to see its passport
          </p>
          {process.env.NODE_ENV !== "production" && ( // Will be released later
            <div className="links">
              (dev){" "}
              <a href="/passport/0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045/0">
                Alessandro Milani's viola 41
              </a>
              |{" "}
              <a
                href="https://testnets.opensea.io/assets/amoy/0xac11a3993c2f810eb1f11ad30a99065ca88b3dd4/0"
                target="_blank"
              >
                Beligent business card on OpenSea (ext.)
              </a>{" "}
            </div>
          )}
          <div className="text-block">
            Explore the digital dimension of your product
          </div>
          <div className="text-block">
            <a href="./">Learn more</a>
          </div>
        </div>
      </div>
    );
  };

  return <div className="PassportSearch">{renderContent()}</div>;
};

export default PassportSearch;
