/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";

import { BsCheck } from "react-icons/bs";

import "./Solutions.scss";

import ContactButton from "../../components/ContactButton/ContactButton";
import { Helmet } from "react-helmet-async";

const Solutions = () => (
  <div className="Solutions">
    <Helmet>
      <title>Beligent - Solutions</title>
    </Helmet>
    {/* ---> Top Hero Section */}
    <div>
      <div className="solution-hero gradient-background white-font">
        <div className="text-box">
          <h1>Beligent's solution for Luxury Goods</h1>
          <h1 className="subtitle">
            Ensuring, Quality, Security and Sustainability
          </h1>
          <hr />
          <p className="main-description">
            Luxury goods are defined by their authenticity, quality, and
            exclusivity. With counterfeit markets and sustainability concerns on
            the rise, our Digital Product Passport (DPP) ensures the integrity
            of your products while offering customers a seamless, secure, and
            transparent ownership experience.
          </p>

          <div>
            <ContactButton></ContactButton>
          </div>
        </div>
      </div>
    </div>

    {/* ---> Counterfeiting box */}
    <div className="counterfeiting-box-outer">
      <div className="counterfeiting-box-inner">
        <h2>Protecting Authenticity with Cutting-Edge Technology</h2>
        <p>
          Our solution integrates blockchain technology and secure product tags
          (NFC or QR codes) to guarantee authenticity. Each luxury item is
          assigned a tamper-proof digital certificate, which:
        </p>
        <ul className="unnumbered-list">
          <li>Authenticates ownership and provenance</li>
          <li>Prevents counterfeiting through instant verification</li>
          <li>
            Provides real-time access to product details with a simple scan
          </li>
        </ul>
      </div>
    </div>

    {/* ---> Detailed Product Information */}
    <div className="informations-section">
      <div className="point-box">
        <h3>Comprehensive Product Information</h3>
        <p>
          The Digital Product Passport contains a wealth of information that
          enhances trust, transparency, and value.
        </p>
      </div>

      {/* ---> Checked Points */}
      <div className="checked-points">
        <div>
          <BsCheck className="check" />
        </div>
        <div className="point-box">
          <h3>Proof of Authenticity</h3>
          <ul className="unnumbered-list">
            <li>Verified details about the creator and craftsmanship</li>
            <li>
              Stored securely using decentralized storage for permanent
              accessibility
            </li>
          </ul>
        </div>
      </div>
      <div className="checked-points">
        <div>
          <BsCheck className="check" />
        </div>
        <div className="point-box">
          <h3>Materials & Sustainability</h3>
          <ul className="unnumbered-list">
            <li>
              Transparent information about raw materials, origins, and ethical
              sourcing
            </li>
            <li>
              Certified compliance with sustainability and environmental
              standards
            </li>
          </ul>
        </div>
      </div>
      <div className="checked-points">
        <div>
          <BsCheck className="check" />
        </div>
        <div className="point-box">
          <h3>Ownership & Lifecycle Records</h3>
          <ul className="unnumbered-list">
            <li>
              Secure chain of ownership, adding to the product's prestige and
              resale value
            </li>
            <li>
              Records of special events, awards, or notable moments in the
              item's history
            </li>
          </ul>
        </div>
      </div>
      <div className="checked-points">
        <div>
          <BsCheck className="check" />
        </div>
        <div className="point-box">
          <h3>Manufacturing & Maintenance</h3>
          <ul className="unnumbered-list">
            <li>Detailed insights into production techniques and artistry</li>
            <li>
              Maintenance logs for repairs and servicing, ensuring longevity and
              resale appeal
            </li>
          </ul>
        </div>
      </div>
    </div>

    {/* ---> Before footer box */}
    <div className="text-section-blue-outer">
      <div className="text-section-blue-inner">
        <h2>Blending Heritage with Innovation</h2>
        <p>
          Our Digital Product Passports merge the finest traditions of luxury
          craftsmanship with cutting-edge blockchain, NFC, and QR code
          technology. By protecting authenticity, enhancing sustainability, and
          enriching product storytelling, we provide a future-proof solution for
          luxury brands in an increasingly connected world.
        </p>
      </div>
    </div>

    {/* ---> Before footer button */}
    <div className="before-footer-button">
      <ContactButton />
    </div>
  </div>
);

export default Solutions;
