import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { authAtom } from "../atoms/authAtom.ts";

const PlatformProtectedRoutes = () => {
  const location = useLocation();
  const { user } = useAtomValue(authAtom);

  if (!user) return <Navigate to={"/auth/login"} />;

  if (user.onboardingStep !== "completed") {
    return <Navigate to={"/onboarding"} />;
  } else if (
    user.role === "owner" &&
    location.pathname === "/platform/passports/create"
  ) {
    return <Navigate to={"/platform/dashboard"} />;
  } else return <Outlet />;
};

export default PlatformProtectedRoutes;
