/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import "./KycForm.scss";
import React, { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DropZone from "../../../components/DropZone/DropZone.tsx";
import { X } from "lucide-react";
import toast from "react-hot-toast";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { useAtomValue } from "jotai";
import { useSetAtom } from "jotai/react";
import { authAtom, updateUserAtom } from "../../../atoms/authAtom.ts";
import { Helmet } from "react-helmet-async";
import { AxiosError } from "axios";

const KycForm = () => {
  const navigate = useNavigate();
  const { user } = useAtomValue(authAtom);
  const setUpdatedUser = useSetAtom(updateUserAtom);
  /** ---> All States. */
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [socialMediaUrl, setSocialMediaUrl] = useState("");
  const [message, setMessage] = useState("");
  const [idCard, setIdCard] = useState<File | null>(null);
  const [proofOfAddress, setProofOfAddress] = useState<File | null>(null);
  const [registrationCertificate, setRegistrationCertificate] =
    useState<File | null>(null);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (user?.role === "creator") {
      if (
        !registrationNumber ||
        !socialMediaUrl ||
        !idCard ||
        !proofOfAddress ||
        !registrationCertificate
      ) {
        toast.error("All fields are required!");
        return;
      }
    } else {
      if (!idCard || !proofOfAddress) {
        toast.error("All fields are required!");
        return;
      }
    }

    toast.loading("Uploading documents.");

    const formData = new FormData();
    formData.append("idCard", idCard);
    formData.append("proofOfAddress", proofOfAddress);
    if (user?.role === "creator") {
      formData.append("registrationNumber", registrationNumber);
      formData.append("socialMediaUrl", socialMediaUrl);
      formData.append("message", message);
      formData.append("registrationCertificate", registrationCertificate!);
    }

    try {
      await axiosClient.post("/auth/kyc", formData, {
        headers: {
          "Content-Type": "multipart-formdata",
        },
      });
      toast.dismiss();
      toast.success("Documents uploaded successfully.");
      setRegistrationNumber("");
      setSocialMediaUrl("");
      setMessage("");
      setIdCard(null);
      setProofOfAddress(null);
      setRegistrationCertificate(null);
      /** ---> Updating local onboarding step. */
      const step = user?.onboardingStep;
      const newStep =
        step === "kyc"
          ? user?.role === "owner"
            ? "kyc-pending"
            : "subscription"
          : step;
      setUpdatedUser({
        onboardingStep: newStep,
      });
      navigate("/onboarding/subscription-selection", { replace: true });
    } catch (error) {
      console.log(error);
      toast.dismiss();
      if (error instanceof AxiosError) {
        toast.error(error.response?.data?.message ?? error.message);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  const maxFileSize = 5 * 1024 * 1024;
  const handleIdCardFileChange = (file: File) => {
    if (file && file.size > maxFileSize) {
      toast.error("File must be less than 5MB.");
      return;
    }
    setIdCard(file);
  };
  const handleProofOfAddressFileChange = (file: File) => {
    if (file && file.size > maxFileSize) {
      toast.error("File must be less than 5MB.");
      return;
    }
    setProofOfAddress(file);
  };
  const handleRegistrationCertificateFileChange = (file: File) => {
    if (file && file.size > maxFileSize) {
      toast.error("File must be less than 5MB.");
      return;
    }
    setRegistrationCertificate(file);
  };

  return (
    <div className="KycForm hero gradient-background ">
      <div className="page-header">
        <h2>Step 3/5: KYC</h2>
        <p>
          Please, provide the following information and documents for the Know
          Your Customer procedure.
        </p>
        <p>
          In case you need, you can leave us a message. We will contact you back
          very quickly.
        </p>
        <Link to={"/onboarding/registration-end"} className="go-back">
          Go back to step 2
        </Link>
      </div>

      <div className="container">
        <Helmet>
          <title>Beligent - KYC</title>
        </Helmet>
        <div className="kyc-form">
          <form onSubmit={handleSubmit} className="form">
            {user?.role === "creator" && (
              <>
                <div className="input-group">
                  <label htmlFor="registrationNumber">
                    Business registration number *
                  </label>
                  <input
                    type="text"
                    id="registrationNumber"
                    name="registrationNumber"
                    value={registrationNumber}
                    onChange={(e) => setRegistrationNumber(e.target.value)}
                    className="input"
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="socialMediaUrl">
                    Professional social media account URL *
                  </label>
                  <input
                    type="text"
                    id="emsocialMediaUrlail"
                    name="socialMediaUrl"
                    value={socialMediaUrl}
                    onChange={(e) => setSocialMediaUrl(e.target.value)}
                    className="input"
                  />
                </div>
              </>
            )}

            <div className="dropzone-group">
              <label htmlFor="email">Upload ID card / passport *</label>
              {!idCard && (
                <DropZone
                  acceptedTypes={{ "image/*": [], "application/pdf": [] }}
                  onFilesSelected={(files) => handleIdCardFileChange(files[0])}
                />
              )}

              {idCard && (
                <>
                  {idCard.type.startsWith("image") ? (
                    <div className="image-preview">
                      <img src={URL.createObjectURL(idCard)} alt="Preview" />
                      <button
                        onClick={() => setIdCard(null)}
                        className="close-button"
                      >
                        <X size={18} />
                      </button>
                    </div>
                  ) : (
                    <div className="pdf-preview">
                      <p>{idCard.name}</p>
                      <button
                        onClick={() => setIdCard(null)}
                        className="close-pdf-button"
                      >
                        <X size={18} />
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="dropzone-group">
              <label htmlFor="email">Proof of address *</label>
              {!proofOfAddress && (
                <DropZone
                  acceptedTypes={{ "image/*": [], "application/pdf": [] }}
                  onFilesSelected={(files) =>
                    handleProofOfAddressFileChange(files[0])
                  }
                />
              )}

              {proofOfAddress && (
                <>
                  {proofOfAddress.type.startsWith("image") ? (
                    <div className="image-preview">
                      <img
                        src={URL.createObjectURL(proofOfAddress)}
                        alt="Preview"
                      />
                      <button
                        onClick={() => setProofOfAddress(null)}
                        className="close-button"
                      >
                        <X size={18} />
                      </button>
                    </div>
                  ) : (
                    <div className="pdf-preview">
                      <p>{proofOfAddress.name}</p>
                      <button
                        onClick={() => setProofOfAddress(null)}
                        className="close-pdf-button"
                      >
                        <X size={18} />
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            {user?.role === "creator" && (
              <>
                <div className="dropzone-group">
                  <label htmlFor="email">
                    Business registration certificate *
                  </label>
                  {!registrationCertificate && (
                    <DropZone
                      acceptedTypes={{ "image/*": [], "application/pdf": [] }}
                      onFilesSelected={(files) =>
                        handleRegistrationCertificateFileChange(files[0])
                      }
                    />
                  )}

                  {registrationCertificate && (
                    <>
                      {registrationCertificate.type.startsWith("image") ? (
                        <div className="image-preview">
                          <img
                            src={URL.createObjectURL(registrationCertificate)}
                            alt="Preview"
                          />
                          <button
                            onClick={() => setRegistrationCertificate(null)}
                            className="close-button"
                          >
                            <X size={18} />
                          </button>
                        </div>
                      ) : (
                        <div className="pdf-preview">
                          <p>{registrationCertificate.name}</p>
                          <button
                            onClick={() => setRegistrationCertificate(null)}
                            className="close-pdf-button"
                          >
                            <X size={18} />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="input-group">
                  <label htmlFor="message">Message</label>
                  <input
                    type="text"
                    id="message"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="input"
                  />
                </div>
              </>
            )}

            <div>
              <button type="submit" className="btn action-button">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default KycForm;
