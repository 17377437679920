import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { authAtom } from "../atoms/authAtom.ts";

const AuthRoutes = () => {
  const location = useLocation();
  const { user } = useAtomValue(authAtom);

  if (!user) return <Outlet />;
  if (location.pathname === "/auth/reset-password") {
    return <Outlet />;
  }
  /** ---> If user already logged in navigate to authenticated rotues. */
  if (user.onboardingStep === "completed") {
    return <Navigate to={"/platform"} />;
  } else return <Navigate to={"/onboarding"} />;
};

export default AuthRoutes;
