/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import "./Onboarding.scss";
import { Routes, Route, Outlet } from "react-router-dom";
import SubscriptionSelection from "../SubscriptionSelection/SubscriptionSelection.tsx";
import SubscriptionPayment from "../SubscriptionPayment/SubscriptionPayment.tsx";
import KycForm from "../KycForm/KycForm.tsx";
import RegistrationEnd from "../RegistrationEnd/RegistrationEnd.tsx";
import KycPending from "../KycPending/KycPending.tsx";

const Onboarding = () => {
  function OnboardingLayout() {
    return (
      <div className="Onboarding">
        <Outlet />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<OnboardingLayout />}>
        <Route path="/registration-end" element={<RegistrationEnd />} />
        <Route path="/kyc-verification" element={<KycForm />} />
        <Route
          path="/subscription-selection"
          element={<SubscriptionSelection />}
        />
        <Route path="/subscription-payment" element={<SubscriptionPayment />} />
        <Route path="/kyc-verification-pending" element={<KycPending />} />
        {/* <Route index element={<h1>Welcome to default platform page</h1>} /> */}
      </Route>
    </Routes>
  );
};

export default Onboarding;
