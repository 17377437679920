/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { FormEvent } from "react";
import { useState } from "react";
import { X } from "lucide-react";
import "./AddDocument.scss";
import PassportPreview from "../../PassportPreview/PassportPreview.js";
import DropZone from "../../../components/DropZone/DropZone.tsx";
import { useAtom } from "jotai";
import { passportPreviewDataAtom } from "../../../lib/atoms.js";
import toast from "react-hot-toast";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { Helmet } from "react-helmet-async";

const AddDocument = () => {
  const [nameOfDocument, setNameOfDocument] = useState("");
  const [dateOfCreation, setDateOfCreation] = useState("");
  const [validUntil, setValidUntil] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [passportPreviewData] = useAtom(passportPreviewDataAtom);

  const handleFilesSelected = (selectedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!files[0]) {
      toast.error("Please select a document first!");
      return;
    }

    const formData = new FormData();
    formData.append("image", files[0]);
    formData.append("nameOfDocument", nameOfDocument);
    formData.append("dateOfCreation", dateOfCreation);
    formData.append("validUntil", validUntil);

    try {
      const res = await axiosClient.post("/passports/add-document", formData);
      toast.success("Document uploaded successfully.");
      setNameOfDocument("");
      setDateOfCreation("");
      setValidUntil("");
      setFiles([]);
    } catch (error) {
      console.log(error);
      toast.success("Document upload failed.");
    }
  };

  return (
    <div className="AddDocument">
      <Helmet>
        <title>Beligent - Add Document</title>
      </Helmet>
      <h1>Attach a document</h1>
      <p>
        You can add an event to your asset. It will be recorded on blockchain so
        make sure that you provide true and accurate information.
      </p>

      <div className="passport-container">
        <PassportPreview passport={passportPreviewData} showLinks={false} />
      </div>

      {/* ---> Drop zone */}
      <div>
        <h3>Upload the document</h3>
        {files.length === 0 && (
          <DropZone
            onFilesSelected={handleFilesSelected}
            acceptedTypes={{ "application/pdf": [] }}
            multiple={true}
          />
        )}

        {/* Display Selected Files with Remove Option */}
        {files.length > 0 && (
          <div className="doc-preview">
            <h4>{files[0].name}</h4>
            <button onClick={() => setFiles([])} className="close-button">
              <X size={18} />
            </button>
          </div>
        )}
      </div>

      {/* ---> Form */}
      <form onSubmit={handleSubmit} className="form">
        <div className="inp-wrapper">
          <label htmlFor="nameOfDocument">Name of document</label>
          <input
            type="text"
            name="nameOfDocument"
            id="nameOfDocument"
            required
            value={nameOfDocument}
            onChange={(e) => setNameOfDocument(e.target.value)}
          />
        </div>
        <div className="inp-wrapper">
          <label htmlFor="dateOfCreation">Date of creation</label>
          <input
            type="date"
            name="dateOfCreation"
            id="dateOfCreation"
            required
            value={dateOfCreation}
            onChange={(e) => setDateOfCreation(e.target.value)}
          />
        </div>
        <div className="inp-wrapper">
          <label htmlFor="validUntil">Valid until</label>
          <input
            type="date"
            name="validUntil"
            id="validUntil"
            required
            value={validUntil}
            onChange={(e) => setValidUntil(e.target.value)}
          />
        </div>

        <div>
          <button className="btn action-button mint-button">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default AddDocument;
