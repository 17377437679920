import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { authAtom } from "../atoms/authAtom.ts";

const OnboardingProtectedRoutes = () => {
  const { user } = useAtomValue(authAtom);
  const location = useLocation();

  if (!user) return <Navigate to={"/auth/login"} />;

  const onboardingRoutes = {
    "register-end": "/onboarding/registration-end",
    kyc: "/onboarding/kyc-verification",
    subscription: "/onboarding/subscription-selection",
    "kyc-pending": "/onboarding/kyc-verification-pending",
    completed: "/platform/dashboard",
  };

  const targetRoute = onboardingRoutes[user.onboardingStep];

  if (
    user.onboardingStep === "kyc-pending" &&
    location.pathname !== "/onboarding/kyc-verification-pending"
  ) {
    return <Navigate to="/onboarding/kyc-verification-pending" replace />;
  }

  if (location.pathname === "/onboarding" && targetRoute) {
    return <Navigate to={targetRoute} replace />;
  }

  return <Outlet />;
};

export default OnboardingProtectedRoutes;
