/**
 * @title Passport Collection Page
 * @author Beligent
 * @description Displays a collection of passports or a message if no passports are available
 * @notice
 * Copyright 2024
 */

import React, { useState, useEffect } from "react";
import { axiosClient } from "../../../lib/axiosClient.ts";
import "./PassportCollection.scss";
import PassportPreview from "../../PassportPreview/PassportPreview";
import { Helmet } from "react-helmet-async";

const PassportCollection = () => {
  const [passports, setPassports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axiosClient.get("passports");
        console.log("Response:", response);

        if (!response || response.status !== 200) {
          throw new Error("Failed to fetch images");
        }
        console.log("User Images:", response.data);
        setPassports(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchImages();
  }, []);

  if (loading) return <p className="text-center">Loading images...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="PassportCollection">
      <Helmet>
        <title>Beligent - Passport Collections</title>
      </Helmet>
      <h2>Your passports</h2>
      <p>Here are all your available digital passports.</p>
      <p>Click them to view more details or add documents and events to it.</p>

      <div className="gallery-container">
        {passports.length > 0 ? (
          passports.map((passport, index) => (
            <PassportPreview key={index} passport={passport} />
          ))
        ) : (
          <p className="text-center text-gray-500">
            You currently don't own any digital passports.
          </p>
        )}
      </div>
    </div>
  );
};

export default PassportCollection;