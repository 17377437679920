import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { axiosClient } from "../../../../lib/axiosClient.ts";

const VerifyEmailChange = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  /** ---> Verifing token on page load. */
  useEffect(() => {
    if (token) {
      handleVerifyOldEmailToken();
    }
  }, []);

  const handleVerifyOldEmailToken = async () => {
    toast.loading("Verifing email.");
    try {
      const res = await axiosClient.post("/auth/email-change-verify", {
        token,
      });
      toast.dismiss();
      toast.success(res.data.message, { duration: 4000 });
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Failed to Update new Email, Token expried or Invalid!", {
        duration: 4000,
      });
    } finally {
      return navigate("/platform/user-settings", { replace: true });
    }
  };

  if (!token) {
    toast.error("Token is required.");
    return <Navigate to={"/platform/user-settings"} replace />;
  }

  return null;
};

export default VerifyEmailChange;
