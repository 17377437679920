/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import "./UserSettings.scss";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosClient } from "../../../lib/axiosClient.ts";
import { IUserProfile } from "../../../interfaces/IUserProfile.ts";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet-async";

const UserSettings = () => {
  const [newEmail, setNewEmail] = useState("");
  const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);
  const [isResetPassSendingEmail, setIsResetPassSendingEmail] = useState(false);

  /** --> Fetching user info onload. */
  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const res = await axiosClient.get("/auth/profile");
      setUserProfile(res.data.userProfile);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmailChange = async () => {
    if (!newEmail) {
      toast.error("Please enter new email!");
      return;
    }

    toast.loading("Sending mail to your old email for verification.");

    try {
      const res = await axiosClient.post("/auth/email-change-request", {
        newEmail,
      });
      setNewEmail("");
      toast.dismiss();
      toast.success(res.data.message, { duration: 4000 });
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Failed to send verification email to old email!");
    }
  };

  const handleForgotPassword = async () => {
    if (!userProfile?.email) return;
    setIsResetPassSendingEmail(true);
    toast.loading("Sending reset password email.");

    try {
      const res = await axiosClient.post("auth/forgot-password", {
        email: userProfile.email,
      });
      toast.dismiss();
      toast.success(res.data.message, { duration: 4000 });
    } catch (error) {
      toast.dismiss();
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message);
      } else {
        toast.error("Invalid email!");
      }
      console.log(error);
    } finally {
      setIsResetPassSendingEmail(false);
    }
  };

  return (
    <div className="UserSettings">
      <Helmet>
        <title>Beligent - User Settings</title>
      </Helmet>
      <div className="profile-settings">
        <div className="sidebar">
          <img
            className="profile-icon"
            src="/user.svg"
          ></img>
          <ul className="menu">
            <li className="menu-item">
              <span>Email : </span> {userProfile?.email}
            </li>
            <li className="menu-item">
              <span>First Name : </span> {userProfile?.firstName}
            </li>
            <li className="menu-item">
              <span>Last Name : </span> {userProfile?.lastName}
            </li>
            <li className="menu-item">
              <span>
                Birth Date :{" "}
                {userProfile?.birthDate
                  ? new Date(userProfile?.birthDate).toLocaleDateString()
                  : "N/A"}
              </span>
            </li>
            <li className="menu-item">
              <span>Nationality : {userProfile?.nationality ?? "N/A"} </span>
            </li>
            <br />
            <span> Address</span>
            <li className="menu-item">
              <span>
                Street Name : {userProfile?.address?.streetName ?? "N/A"}{" "}
              </span>
            </li>
            <li className="menu-item">
              <span>
                Street Number : {userProfile?.address?.streetNumber ?? "N/A"}
              </span>
            </li>
            <li className="menu-item">
              <span>
                Postal Code : {userProfile?.address?.postCode ?? "N/A"}{" "}
              </span>
            </li>
            <li className="menu-item">
              <span>City : {userProfile?.address?.city ?? "N/A"} </span>
            </li>
            <li className="menu-item">
              <span>Region : {userProfile?.address?.region ?? "N/A"} </span>
            </li>
            <li className="menu-item">
              <span>Country : {userProfile?.address?.country ?? "N/A"} </span>
            </li>

            {userProfile?.company && (
              <>
                <br />
                <span>Company </span>
                <li className="menu-item">
                  <span>Company Name : {userProfile?.company?.name} </span>
                </li>
                <li className="menu-item">
                  <span>
                    Social Media :{" "}
                    {userProfile?.company?.professionalSocialMediaUrl}{" "}
                  </span>
                </li>
                <li className="menu-item">
                  <span>
                    Company Registration Number :{" "}
                    {userProfile?.company?.registrationNumber}{" "}
                  </span>
                </li>
                <li className="menu-item">
                  <span>Position : {userProfile?.company?.role} </span>
                </li>
              </>
            )}

            {userProfile?.company && (
              <>
                <br />
                <span>Company Address</span>
                {userProfile?.company?.addressIsSame ? (
                  <li className="menu-item"> Address is same</li>
                ) : (
                  <>
                    <li className="menu-item">
                      <span>
                        Street Name :{" "}
                        {userProfile?.company?.companyAddress?.streetName}{" "}
                      </span>
                    </li>
                    <li className="menu-item">
                      <span>
                        Postal Code :{" "}
                        {userProfile?.company?.companyAddress?.postCode}{" "}
                      </span>
                    </li>
                    <li className="menu-item">
                      <span>
                        City : {userProfile?.company?.companyAddress?.city}{" "}
                      </span>
                    </li>
                    <li className="menu-item">
                      <span>
                        Region : {userProfile?.company?.companyAddress?.region}{" "}
                      </span>
                    </li>
                    <li className="menu-item">
                      <span>
                        Country :{" "}
                        {userProfile?.company?.companyAddress?.country}{" "}
                      </span>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>
        </div>
        <main className="content">
          <h2>
            Welcome, {userProfile?.firstName}
          </h2>
          <p className="description">
            Manage your personal information here. You can update your email and
            password, and contact us for other requests.
          </p>

          <section className="email-section">
            <h3>Email</h3>
            <p>1 - Enter your new email below.</p>
            <p>2 - Check your inbox for a confirmation link.</p>
            <div className="email-input">
              <input
                type="email"
                placeholder="Enter new email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
              <button onClick={handleEmailChange}>Change Email</button>
            </div>
          </section>

          <section className="password-section">
            <h3>Password</h3>
            <p>1 - Click the button below.</p>
            <p>2 - Check your inbox for a confirmation link.</p>
            <p>3 - Set your new password.</p>
            <span style={{ fontSize: 20, fontWeight: "bold", marginRight: 20 }}>
              Change password :
            </span>
            <button
              disabled={isResetPassSendingEmail}
              className="password-btn"
              onClick={handleForgotPassword}
            >
              Send me a verification email
            </button>
          </section>

          <section className="other-changes">
            <h3>Other Changes</h3>
            <p>
              For any other changes, please{" "}
              <Link to="/contact">contact us</Link>.
            </p>
          </section>
        </main>
      </div>
    </div>
  );
};

export default UserSettings;
