import React, { FC, ReactNode, useState } from "react";

interface IProps {
  title: string;
  isDefaultOpen?: boolean;
  children: ReactNode;
}

const Accordion: FC<IProps> = ({ title, isDefaultOpen = false, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(isDefaultOpen);

  const toggleSection = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="accordion" id={`accordion-${title}`}>
      <div className="accordion-item">
        <h2 className="accordion-header" id={`heading-${title}`}>
          <button
            className={`accordion-button ${isOpen ? "" : "collapsed"}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse-${title}`}
            aria-controls={`collapse-${title}`}
            onClick={toggleSection}
          >
            {title}
          </button>
        </h2>
        <div
          id={`collapse-${title}`}
          className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
          aria-labelledby={`heading-${title}`}
          data-bs-parent={`#accordion-${title}`}
        >
          <div className="accordion-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
