import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useMatch } from "react-router-dom";

class ImageSlider extends React.Component {
  handleSlide = (index) => {
    const { onImageChange, images } = this.props;
    if (onImageChange && images?.length) {
      onImageChange(images[index]);
    }
  };
  render() {
    return (
      <ImageGallery
        items={this.props.images}
        onSlide={this.handleSlide}
        lazyLoad={true}
      />
    );
  }
}

const ImageSliderWrapper = (prop) => {
  const images = prop.image ?? [];

  const [selectedImage, setSelectedImage] = useState(null);

  const match = useMatch("/platform/passports/passport-details/:address?/:id?");
  
  const platformPassport = !!match;

  if(platformPassport) {
    import("./DashboardImageSlider.scss");
  }
  else {
    import("./ImageSlider.scss");
  }

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]);
    }
  }, images);

  const handleImageChange = (image) => {
    setSelectedImage(image);
    console.log("Current Image:", image);
  };

  const renderImagesData = () => (
    <>
      <div className="row mb-4">
        <div className="col-4 text-muted">File name:</div>
        <div className="col-8">{selectedImage?.fileName}</div>
      </div>
      <div className="row mb-4">
        <div className="col-4 text-muted">Storage:</div>
        <div className="col-8">{selectedImage?.original}</div>
      </div>
      <div className="row mb-4">
        <div className="col-4 text-muted">Description:</div>
        <div className="col-8">{selectedImage?.imageDescription}</div>
      </div>
      <div className="row mb-4">
        <div className="col-4 text-muted">Timestamp:</div>
        <div className="col-8">{selectedImage?.timeStamp}</div>
      </div>
    </>
  );

  return (
    <div className="ImageSlider">
      <div className="row">
        <div className="col-md-12">
          <div
            className="container"
          >
            <div className="center-div">
              <ImageSlider images={images} onImageChange={handleImageChange} />
            </div>
            <div className="right-div">{renderImagesData()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSliderWrapper;
