/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";

import "./TermsOfUse.scss";
import { Helmet } from "react-helmet-async";

const TermsOfUse = () => (
  <div className="TermsOfUse">
    <Helmet>
      <title>Beligent - Terms Of Use</title>
    </Helmet>
    <div class="container">
      <h1>Terms of Use</h1>

      <p>
        Welcome to our website! By accessing or using our SaaS platform and
        services, you agree to comply with and be bound by these Terms of Use.
        Please read them carefully before proceeding.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using our platform, you acknowledge that you have read,
        understood, and agree to be bound by these Terms of Use. If you do not
        agree to these terms, you must not use our platform.
      </p>

      <h2>2. Eligibility</h2>
      <p>
        You must be at least 18 years old or have the legal capacity to enter
        into a binding agreement in your jurisdiction to use our platform.
      </p>

      <h2>3. Account Registration</h2>
      <p>
        To access certain features of our platform, you may need to create an
        account. You agree to:
      </p>
      <ul>
        <li>Provide accurate and complete information during registration.</li>
        <li>Keep your account credentials confidential and secure.</li>
        <li>Notify us immediately of any unauthorized use of your account.</li>
      </ul>

      <h2>4. Use of the Platform</h2>
      <p>
        You agree to use the platform only for lawful purposes and in compliance
        with these Terms of Use. You agree not to:
      </p>
      <ul>
        <li>
          Engage in any activity that disrupts or interferes with the platform's
          functionality.
        </li>
        <li>Use the platform for unauthorized or illegal purposes.</li>
        <li>
          Access or attempt to access another user's account without permission.
        </li>
      </ul>

      <h2>5. Intellectual Property</h2>
      <p>
        All content, software, and intellectual property on the platform are
        owned by Beligent or its licensors. You are granted a limited,
        non-exclusive, and revocable license to use the platform for its
        intended purpose. You may not reproduce, modify, or distribute any
        content without our prior written consent.
      </p>

      <h2>6. Subscription and Payment</h2>
      <p>
        Access to certain features of the platform may require a subscription.
        By subscribing, you agree to pay all fees associated with your
        subscription plan. All payments are non-refundable unless otherwise
        stated.
      </p>

      <h2>7. Termination</h2>
      <p>
        We may suspend or terminate your access to the platform if you violate
        these Terms of Use or engage in unauthorized activity. You may terminate
        your account at any time by contacting us.
      </p>

      <h2>8. Disclaimer of Warranties</h2>
      <p>
        The platform is provided "as is" without warranties of any kind, express
        or implied. We do not guarantee that the platform will be error-free or
        uninterrupted.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>
        To the maximum extent permitted by law, Beligent shall not be liable for
        any indirect, incidental, or consequential damages arising from your use
        of the platform.
      </p>

      <h2>10. Governing Law</h2>
      <p>
        These Terms of Use are governed by and construed in accordance with the
        laws of Switzerland. Any disputes arising from these terms shall be
        resolved in the courts of [City], Switzerland.
      </p>

      <h2>11. Changes to These Terms</h2>
      <p>
        We may update these Terms of Use from time to time. Significant changes
        will be notified to you via email or a notification on our platform.
      </p>

      <h2>12. Contact Us</h2>
      <p>
        If you have any questions about these Terms of Use, please contact us
        at:
      </p>
      <p>
        Beligent <br />
        Zurich, Switzerland <br />
        Email: <span className="">contact(at)beligent.io</span>
      </p>
    </div>
  </div>
);

export default TermsOfUse;
