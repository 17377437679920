import React, { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { axiosClient } from "../../lib/axiosClient.ts";
import toast from "react-hot-toast";
import { AxiosError } from "axios";
import { useSetAtom } from "jotai";
import { loginAtom } from "../../atoms/authAtom.ts";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const setLogin = useSetAtom(loginAtom);

  useEffect(() => {
    handleVerifyEmail();
  }, []);

  const handleVerifyEmail = async () => {
    if (!token) {
      toast.error("Token is required!");
      return;
    }
    try {
      const res = await axiosClient.post("/auth/verify-email", { token });
      const data = res.data;
      setLogin({ user: data.user });
      toast.success("Email has been verified successfully.");
      navigate("/onboarding");
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.message);
      }
    }
  };

  return <Navigate to={"/platform/dashboard"} />;
};

export default VerifyEmail;
