/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React, { useState } from "react";
import { validateEmail } from "../../lib/utils";
import toast from "react-hot-toast";
import { axiosClient } from "../../lib/axiosClient.ts";

import "./ContactUs.scss";
import { Helmet } from "react-helmet-async";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = async () => {
    if (!name || !email || !message) {
      toast.error("All fields are required!");
      return;
    } else if (!validateEmail(email)) {
      toast.error("Please enter a valid email!");
      return;
    }

    try {
      await axiosClient.post("contact", {
        name,
        email,
        message,
      });
      toast.success("Message sent successfully!");

      setName("");
      setEmail("");
      setMessage("");
      setErrors({});
    } catch (error) {
      console.error("Error sending message:", error);
      setErrors({ server: "Error sending message. Please try again later." });
    }
  };
  return (
    <div className="ContactUs">
      <Helmet>
        <title>Beligent - Contact Us</title>
      </Helmet>
      {/* ---> Top Hero Section */}
      <div className="hero gradient-background white-font">
        <div className="text-box">
          <h1>Let's Build a Future of Trust and Transparency</h1>
          <hr />
          <p className="main-description">
            Have questions about Digital Product Passports?
            <br />
            Want to learn how to protect your brand and enhance customer trust?
            <br />
            -<br />
            We're here to help.
          </p>
        </div>
      </div>

      <div className="section-content">
        <div className="text-section-outer">
          <div className="text-section-inner">
            <h2>Get in Touch</h2>
            <p>
              Whether you're a brand looking to safeguard authenticity, comply
              with regulations, or provide a seamless customer experience, our
              team is ready to assist you.
            </p>
            <br />
            <h2>Let's Start Your Digital Product Passport Journey</h2>
            <p>
              Tell us more about your needs, and we'll help you find the best
              solution. Fill out the form below, and we'll get back to you
              shortly.
            </p>
          </div>
        </div>
      </div>

      {/* ---> Before footer box */}
      <div className="form-section">
        <h2>Contact us</h2>
        <div className="form-group">
          <span>Your name * </span>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <span>Your email *</span>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <span>Your message *</span>
          <textarea
            cols={80}
            rows={6}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        * Mandatory fields
        <br />
        <br />
        <div className="SendButton">
          <div className="button-container">
            <div className="block-center">
              <div className="">
                <button
                  type="button"
                  className="btn action-button send-button"
                  onClick={handleSubmit}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
