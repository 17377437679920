/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";

import "./About.scss";
import ContactButton from "../../components/ContactButton/ContactButton";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <div className="About">
      <Helmet>
        <title>Beligent - About</title>
      </Helmet>
      {/* ---> Top Hero Section */}
      <div className="hero gradient-background white-font">
        <div className="text-box">
          <h1>About Beligent</h1>
          <hr />
          <p className="main-description">
            At Beligent, we believe we can enhance every product with a digital
            dimension. A new dimension that we are proud to bring to our
            customers because with today's technology we can consume better.{" "}
            <br />
            Thanks to our technology, we can bring safety, transparency, and
            sustainability to the luxury goods market.
          </p>
          <div className="contact-button">
            <ContactButton />
          </div>
        </div>
      </div>
      {/* ---> Context box */}
      <div className="context-box-outer">
        <div className="context-box-inner">
          <p>
            We are a team of passionate individuals, engineers and violin
            specialists working together to make the best product.
            <br />
            We aim at providing violinists with a new way to experience their
            violin with a digital command center that will allow them to prove
            their ownership, their certification. And this, from anywhere.
          </p>
        </div>
      </div>
      {/* ---> Points */}
      <div className="content-section">
        <div className="content-box">
          <p>
            Over the time, we have grown into a team of dedicated professionals,
            each bringing unique skills and expertise. Whether it's web
            development, design, mobile applications, or violin making, we
            believe in pushing boundaries and staying ahead of the curve.
          </p>
          <p>
            Collaboration, integrity, and a relentless pursuit of quality define
            our work culture.
          </p>
        </div>
        <div className="content-box">
          <p>
            Looking ahead, we remain dedicated to continuous improvement and
            innovation. We believe that the best way to predict the future is to
            create it, and we are excited to embark on new challenges that shape
            the digital landscape.
            <br />
            Join us on this journey as we continue to build, learn, and inspire.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
