/**
 * @title
 * @author Beligent
 * @notice
 * Copyright 2024
 */

import React from "react";
import "./HomePage.scss";
import ContactButton from "../../components/ContactButton/ContactButton";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
  return (
    <div className="HomePage">
      <Helmet>
        <title>Beligent</title>
      </Helmet>
      <div>
        <div className="hero gradient-background white-font">
          <div className="text-box">
            <h1>Digital Product Passport</h1>
            <h1 className="subtitle">Your Product's DNA</h1>
            <hr />
            <p className="main-description">
              A Digital Product Passport allows you to protect your brand,
              combat counterfeiting, simplify compliance and provide customers
              with detailed information about your products.
              <br />
              <br />
              Empower your customers, provide them with a unique experience, and
              increase their trust in your brand.
            </p>

            <div></div>
            <div>
              <ContactButton></ContactButton>
            </div>
          </div>

          <div className="phone-container">
            <img src="./tel.png" className="frame img-fluid unblur" />
          </div>
        </div>
      </div>

      <div id="advantages-section">
        <h1 className="center">Advantages for Brands and Customers</h1>
        <h2 className="center">
          A product-centric approach.
          <br />
          All information and services directly from the product itself.
        </h2>
        <div className="flex-container white-background">
          <div className="advantages advantages-container row">
            <div className="advantages column">
              <h2>
                <span className="square-list-item square-list-item-big"></span>
                FOR YOUR BRAND
              </h2>
              <ul className="advantages">
                <li className="advantages-list dark-box">
                  <h3>
                    <span className="square-list-item square-list-item-small"></span>
                    Combat Counterfeiting
                  </h3>
                  <p className="advantages-list-description">
                    Reinforce the authenticity of your products with a digitally
                    signed and long-lasting Certificate of Authenticity provided
                    by a verified creator.
                  </p>
                </li>
                <li className="advantages-list dark-box">
                  <h3>
                    <span className="square-list-item square-list-item-small"></span>
                    Brand Reputation
                  </h3>
                  <p className="advantages-list-description">
                    By providing detailed and transparent product information,
                    brands can build trust with consumers, improving their
                    reputation and credibility.
                  </p>
                </li>
                <li className="advantages-list dark-box">
                  <h3>
                    <span className="square-list-item square-list-item-small"></span>
                    Simplified Compliance
                  </h3>
                  <p className="advantages-list-description">
                    Ensure adherence to various regulatory requirements
                    regarding product information, safety standards, and
                    sustainability disclosures, minimizing legal risks.
                  </p>
                </li>
                <li className="advantages-list dark-box">
                  <h3>
                    <span className="square-list-item square-list-item-small"></span>
                    Direct Customer Engagement
                  </h3>
                  <p className="advantages-list-description">
                    Keep a direct line of communication with the real owner of
                    your products, providing them with tailored services and
                    recommendations.
                  </p>
                </li>
              </ul>
            </div>
            <div className="advantages column">
              <h2>
                <span className="square-list-item square-list-item-big"></span>
                FOR YOUR CUSTOMERS
              </h2>
              <ul className="advantages">
                <li className="advantages-list light-box">
                  <h3>
                    <span className="square-list-item square-list-item-small"></span>
                    Product Authenticity
                  </h3>
                  <p className="advantages-list-description">
                    Provide second-hand customers with an easy way to check
                    authenticity, reducing the risk of purchasing counterfeit
                    products.
                  </p>
                </li>
                <li className="advantages-list light-box">
                  <h3>
                    <span className="square-list-item square-list-item-small"></span>
                    All Documentation in One Place
                  </h3>
                  <p className="advantages-list-description">
                    Customers simply scan the product with their smartphone to
                    access all documents and certifications, anywhere and
                    anytime.
                  </p>
                </li>
                <li className="advantages-list light-box">
                  <h3>
                    <span className="square-list-item square-list-item-small"></span>
                    Sustainability, Ethical Sourcing
                  </h3>
                  <p className="advantages-list-description">
                    Empower customers through traceability information and
                    certifications that help them select products aligned with
                    their values.
                  </p>
                </li>
                <li className="advantages-list light-box">
                  <h3>
                    <span className="square-list-item square-list-item-small"></span>
                    Personalized Services
                  </h3>
                  <p className="advantages-list-description">
                    Customers receive tailored recommendations and services
                    related to their specific products and preferences,
                    enhancing their experience.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="howitworks-section gradient-background">
        <div className="howitworks-container">
          <h1 className="center howitworks-headline">How it works</h1>

          <p className="howitworks-subtitle">
            We support you throughout the entire process, offering tailored
            advice and creating your unique digital passport to ensure
            authenticity and traceability.
          </p>

          <div className="step-container">
            <div className="white-box">
              <div className="step-content">
                <div className="step-icon">
                  <img src="./howitworks-icon1.svg" alt="Assess Icon" />
                </div>
                <div className="step-line"></div>
                <div className="step-text">
                  <h2 className="step-title">Assess</h2>
                  <p className="step-description">
                    We start by studying your case and identifying the right
                    solution tailored to your needs.
                  </p>
                </div>
                <div className="step-number">
                  <span>01.</span>
                </div>
              </div>
            </div>
            <div className="white-box">
              <div className="step-content">
                <div className="step-icon">
                  <img src="./howitworks-icon2.svg" alt="Register Icon" />
                </div>
                <div className="step-line"></div>
                <div className="step-text">
                  <h2 className="step-title">Register</h2>
                  <p className="step-description">
                    Identify yourself and submit your asset's details.
                  </p>
                </div>
                <div className="step-number">
                  <span>02.</span>
                </div>
              </div>
            </div>
            <div className="white-box">
              <div className="step-content">
                <div className="step-icon">
                  <img src="./howitworks-icon3.svg" alt="Record Icon" />
                </div>
                <div className="step-line"></div>
                <div className="step-text">
                  <h2 className="step-title">Record</h2>
                  <p className="step-description">
                    Our system records your item on the blockchain, ensuring an
                    immutable record of authenticity.
                  </p>
                </div>
                <div className="step-number">
                  <span>03.</span>
                </div>
              </div>
            </div>
            <div className="white-box">
              <div className="step-content">
                <div className="step-icon">
                  <img src="./howitworks-icon4.svg" alt="Verify Icon" />
                </div>
                <div className="step-line"></div>
                <div className="step-text">
                  <h2 className="step-title">Verify</h2>
                  <p className="step-description">
                    You digital certificate can be verified globally in seconds!
                  </p>
                </div>
                <div className="step-number">
                  <span>04.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="case-study">
        <div className="case-study-container">
          <h1 className="center">Case Study</h1>
          <div className="section-content">
            <h2 className="center">
              Bringing trust to luxury goods, art or collectibles, our
              blockchain certification process is adaptable offers a new
              standard in authenticity verification and compliance
              documentation.
            </h2>
          </div>
          <div className="case-study-content">
            <div className="row">
              <div className="column">
                <img src="./luthier-logo.png" className="luthier-logo"></img>
              </div>
              <div className="column">
                <img src="./certified.png" className="certified" />
              </div>
            </div>
            <div className="row case-study-text-container">
              <div className="column case-study-text">
                <h2 className="blue-title">
                  Alessandro Milani - Master Luthier
                </h2>
                <div>
                  <p className="">
                    Alessandro enhances his handcrafted violins with a modern
                    touch by providing a Certificate of Authenticity and
                    certification information, ensuring each instrument's
                    authenticity and providing detailed information through a
                    simple tap.
                  </p>
                </div>
              </div>
              <div className="column violin-container">
                <img src="./violin.png" className="violin unblur" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-section gradient-background">
        <div className="contact-container">
          <h1 className="center">Contact us today!</h1>
          <div className="section-content">
            <h2 className="">
              Have a project in mind? Reach out to us today! We're excited to
              assist you in bringing your vision to life with our expertise and
              innovative solutions. Contact us now to discuss how we can help
              you achieve your goals.
            </h2>
          </div>
          <div>
            <ContactButton></ContactButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
